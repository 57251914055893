import moment from 'moment';
import secureLocalStorage from "react-secure-storage";

export function getRequestParams(methodType, request) {

    if (methodType === "GET") {
        const requestParams = {
            method: methodType,
            headers: {
                'Content-Type': 'application/json',
                'CultureCode': secureLocalStorage.getItem('session_culturecode'),
                'Authorization': 'Bearer ' + secureLocalStorage.getItem('session_token')
            },
        }
        return requestParams;
    }
    else if (methodType === "DELETE") {
        const requestParams = {
            method: methodType,
            headers: {
                'Content-Type': 'application/json',
                'CultureCode': secureLocalStorage.getItem('session_culturecode'),
                'Authorization': 'Bearer ' + secureLocalStorage.getItem('session_token')
            },
        }
        return requestParams;
    }
    else if (methodType === "PUT" && request !== null) {
        const requestParams = {
            method: methodType,
            headers: {
                'Content-Type': 'application/json',
                'CultureCode': secureLocalStorage.getItem('session_culturecode'),
                'Authorization': 'Bearer ' + secureLocalStorage.getItem('session_token')
            },
            body: JSON.stringify(request)
        }
        return requestParams;
    }

    else if (methodType === "PUT" && request === null) {
        const requestParams = {
            method: methodType,
            headers: {
                'Content-Type': 'application/json',
                'CultureCode': secureLocalStorage.getItem('session_culturecode'),
                'Authorization': 'Bearer ' + secureLocalStorage.getItem('session_token')
            },
        }
        return requestParams;
    }
    else {
        const requestParams = {
            method: methodType,
            headers: {
                'Content-Type': 'application/json',
                'CultureCode': secureLocalStorage.getItem('session_culturecode'),
                'Authorization': 'Bearer ' + secureLocalStorage.getItem('session_token')
            },
            body: JSON.stringify(request)
        }
        return requestParams;
    }
}

export function formatDate(date, dateformat) {
    if (dateformat === "MM/dd/yyyy") {
        return moment(date).format("MM/DD/YYYY");
    }
    if (dateformat === "dd/MM/yyyy") {
        return moment(date).format("DD/MM/YYYY");
    }
    if (dateformat === "MMM/dd/yyyy") {
        return moment(date).format("MMM") + "-" + moment(date).format("D") + "-" + moment(date).format("YYYY");
    }
    if (dateformat === "dd/MMM/yyyy") {
        return moment(date).format("D") + "-" + moment(date).format("MMM") + "-" + moment(date).format("YYYY");
    }
    if (dateformat === "yyyy-MM-dd") {
        return moment(date).format("YYYY-MM-DD");
    }
    if (dateformat === "yyyy/MM/dd") {
        return moment(date).format("YYYY/MM/DD");
    }
    if (dateformat === "MM/dd/yyyy hh:mm") {
        return moment(date).format("MM/DD/YYYY HH:mm");
    }
    if (dateformat === "MM/dd/yyyy hh:mm:ss") {
        return moment(date).format("MM/DD/YYYY HH:mm:ss");
    }
    if (dateformat === "dd/MM/yyyy hh:mm") {
        return moment(date).format("DD/MM/YYYY HH:mm");
    }
    if (dateformat === "dd/MM/yyyy hh:mm:ss") {
        return moment(date).format("DD/MM/YYYY HH:mm:ss");
    }
    if (dateformat === "yyyy-MM-dd HH:mm:ss") {
        return moment(date).format("YYYY-MM-DD HH:mm:ss");
    }
    if (dateformat === "yyyyMMddHHmmss") {
        return moment(date).format("YYYYMMDDHHmmss");
    }
    if (dateformat === "yyyyMMdd" || dateformat === "yyyymmdd") {
        return moment(date).format("YYYYMMDD");
    }
    if (dateformat === "MMM") {
        return moment(date).format("MMM");
    }
    if (dateformat === "D") {
        return moment(date).format("D");
    }
    if (dateformat === "HH:mm:ss") {
        return moment(date).format("HH:mm:ss");
    }
    return moment(date).format("MM/DD/YYYY HH:mm:ss");
}

export function formatTime(date, timeformat) {
    var d = new Date(date),
        month = '' + (d.getMonth() + 1),
        day = '' + d.getDate(),
        year = d.getFullYear();
    var hour = '' + d.getHours();
    var minutes = '' + d.getMinutes();
    var seconds = '' + d.getSeconds();

    if (timeformat === "hh:mm") {
        if (month.length < 2)
            month = '0' + month;
        if (day.length < 2)
            day = '0' + day;

        if (hour <= 9) {
            hour = '0' + hour;
        }

        if (minutes <= 9) {
            minutes = '0' + minutes;
        }

        return [hour, minutes].join(':');
    }

    if (timeformat === "hh:mm:ss") {
        if (month.length < 2)
            month = '0' + month;
        if (day.length < 2)
            day = '0' + day;
        if (hour <= 9) {
            hour = '0' + hour;
        }

        if (minutes <= 9) {
            minutes = '0' + minutes;
        }

        if (seconds <= 9) {
            seconds = '0' + seconds;
        }

        return [hour, minutes, seconds].join(':');
    }

    return [hour, minutes, seconds].join(':');
}

export function timeDiffCalc(futureDate, currentDate, format) {
    let diffInMilliSeconds = Math.abs(new Date(futureDate) - new Date(currentDate)) / 1000;

    // calculate days
    const days = Math.floor(diffInMilliSeconds / 86400);
    diffInMilliSeconds -= days * 86400;

    // calculate hours
    const hours = Math.floor(diffInMilliSeconds / 3600) % 24;
    diffInMilliSeconds -= hours * 3600;

    // calculate minutes
    const minutes = Math.floor(diffInMilliSeconds / 60) % 60;
    diffInMilliSeconds -= minutes * 60;

    const seconds = Math.floor(diffInMilliSeconds / 1000);

    let difference = '';

    if (format === "DHM") {
        if (days > 0) {
            difference += (days <= 1) ? `${days} day, ` : `${days} days, `;
        }
        difference += (hours <= 1) ? `${hours} hrs, ` : `${hours} hrs, `;
        difference += (minutes <= 1) ? `${minutes} mins` : `${minutes} mins`;
    }
    else if (format === "HM") {
        let _hours = hours;
        if (days > 0) {
            _hours = _hours + (days * 24);
        }
        difference += (_hours <= 1) ? `${_hours} hrs, ` : `${_hours} hrs, `;
        difference += (minutes <= 1) ? `${minutes} mins` : `${minutes} mins`;
    }
    else if (format === "D") {
        difference += (days <= 1) ? `${days} day, ` : `${days} days, `;
    }
    else if (format === "H") {
        difference += (hours <= 1) ? `${hours} hrs, ` : `${hours} hrs, `;
    }
    else if (format === "M") {
        difference += (minutes <= 1) ? `${minutes} mins` : `${minutes} mins`;
    }
    else {
        difference += (hours <= 1) ? `${hours} hrs, ` : `${hours} hrs, `;
        difference += (minutes <= 1) ? `${minutes} mins` : `${minutes} mins`;
    }

    return difference;
}

export function HasValue(str) {
    return !!str && /^\s*$/.test(str) === false;
}

export function getCurrentDateTime() {
    return secureLocalStorage.getItem("session_locationTime") == null || secureLocalStorage.getItem("session_locationTime") == "";
}

export function getVehicleLabelByIndustry(text) {
    if (text != null && text.length > 0 && secureLocalStorage.getItem("session_industryTypeId") == "4") {
        return text.replace("Vehicle", "Ambulance");
    }
    return text;
}

export function isNullOrEmpty(value) {
    return value === undefined || value === null || value === '';
}

export function getIconColorByVehicleStatus(status) {
    var color = 'gray';
    switch (status) {
        case 'Moving':   //statusId = 1
            color = 'green';
            break;
        case 'Stopped':   //2
            color = 'red';
            break;
        case 'Idle':   // 3
            color = 'orange';
            break;
        case 'Out Of Service':
            color = 'blue';
            break;
        default:
            color = color;
            break;
    }
    return color;

}

export function getIconColorByTripStatus(status) {
    var color = 'gray';
    switch (status) {
        case "START":
            color = '#00a65a';
            break;
        case "START TRIP":
            color = '#00a65a';
            break;
        case "ON ROAD":
            color = '#ffee1a';
            break;
        case "OFF ROAD":
            color = '#333333';
            break;
        case "ON SCENE":
            color = '#17ffff';
            break;
        case "ON BOARD":
            color = '#ff8a15';
            break;
        case "HOSPITAL IN":
            color = '#1a26ff';
            break;
        case "HOSPITAL OUT":
            color = '#a203ff';
            break;
        case "CLEAR":
            color = '#dd4b39';
            break;
        default:
            color = color;
    }
    return color;

}

export function getIconColorByGPSSignalStatus(status) {
    var color = 'gray';
    if (status == 1) {
        color = 'green';
    }
    return color;
}

export function getIconColorByOnlineStatus(status) {
    var color = 'gray';
    if (status == 1) {
        color = 'green';
    }
    return color;
}

export function parseBoolean(value) {
    return value === true || (value + '').toLowerCase() === 'true';
}
