import React, { Component, Fragment } from 'react';
import { NavItem, NavLink } from 'reactstrap';
import { Link } from 'react-router-dom';
import { getRequestParams } from '../../src/helpers/globalfunctions.js';
import { TopUserSetupComponent } from '../components/Shared/TopUserSetupComponent';
import  secureLocalStorage  from  "react-secure-storage";

export class TopBarComponent extends Component {
    static displayName = TopBarComponent.name;    
    constructor(props) {
        super(props);        
        this.state = {
            displayName: TopBarComponent.name,
            pageName: secureLocalStorage.getItem("PageName") === null ? "Dashboard" : secureLocalStorage.getItem("PageName"),
        }
    }

    componentDidMount() {
       console.log(secureLocalStorage.getItem("PageName"));
    }
  
    handleLogout = async (e) => {
        e.preventDefault();        
        const requestParams = getRequestParams('GET');
        let userId = parseInt(secureLocalStorage.getItem("session_userId"));
        let loginHistoryId = parseInt(secureLocalStorage.getItem("session_LoginHistoryId"));        
        const response = await fetch(sessionStorage.getItem("REACT_APP_API_BASE_URL") + 'authentication/logout/' + userId + '/' + loginHistoryId, requestParams);
        secureLocalStorage.clear();
        window.location.href = "/";
    }

    toggleSidebar=(e)=>{
        e.preventDefault();
        if(document.getElementsByTagName("body")[0].className === "sidebar-mini"){
            document.getElementsByTagName("body")[0].classList.remove("sidebar-mini")
        }else{
            document.getElementsByTagName("body")[0].classList.add("sidebar-mini")
        }      
    }

    render() {
        return (
            <Fragment>
                <div className="navbar-bg"></div>
                <nav className="navbar navbar-custom navbar-expand-lg main-navbar" name="topGeneralBar">
                    <form className="form-inline mr-auto">
                        <ul className="navbar-nav mr-3">
                            {/* <NavItem>
                                <NavLink className="nav-link-lg" tag={Link} to="#" data-toggle="sidebar"><i className="fas fa-bars"></i></NavLink>
                            </NavItem> */}
                            <li><a href="#" data-toggle="sidebar" className="nav-link nav-link-lg"><i className="fas fa-bars"></i></a></li>
                            <li className="nav-item">
                                <h6 className='mb-0'><label id='lblPageTitle'></label></h6>
                            </li>
                        </ul>
                    </form>
                    <ul className="navbar-nav navbar-right">                        
                        <TopUserSetupComponent />
                    </ul>
                </nav>
            </Fragment>
        );
    }
}