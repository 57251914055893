import React, { Component, Fragment, Suspense, lazy } from 'react';
import { Route } from 'react-router';
//import { Wrapper } from "@googlemaps/react-wrapper";

import 'devextreme/dist/css/dx.common.css';
import 'devextreme/dist/css/dx.light.css';

import { Layout } from './components/Layout';
import { AuthorisedLayout } from './components/AuthorisedLayout';
import { LoginComponent } from './components/LoginComponent';
//import { HomeComponent } from './components/home/HomeComponent';
import secureLocalStorage from "react-secure-storage";
import { getRequestParams } from './helpers/globalfunctions.js';

//const  Layout  = lazy(() => import('./components/Layout').then(module => ({ default: module. Layout  })));
//const  AuthorisedLayout  = lazy(() => import('./components/AuthorisedLayout').then(module => ({ default: module. AuthorisedLayout  })));
const ForgotPasswordComponent = lazy(() => import('./components/ForgotPasswordComponent').then(module => ({ default: module.ForgotPasswordComponent })));
const RegisterComponent = lazy(() => import('./components/RegisterComponent').then(module => ({ default: module.RegisterComponent })));
//const  LoginComponent  = lazy(() => import('./components/LoginComponent').then(module => ({ default: module. LoginComponent  })));
const HomeComponent = lazy(() => import('./components/home/HomeComponent').then(module => ({ default: module.HomeComponent })));
const Wrapper = lazy(() => import('@googlemaps/react-wrapper').then(module => ({ default: module.Wrapper })));

const VehicleStatusComponent = lazy(() => import('./components/vehicle/VehicleStatusComponent').then(module => ({ default: module.VehicleStatusComponent })));
const VehicleReplayComponent = lazy(() => import('./components/replay/VehicleReplayComponent').then(module => ({ default: module.VehicleReplayComponent })));
const PointOfIntrestComponent = lazy(() => import('./components/poi/PointOfIntrestComponent').then(module => ({ default: module.PointOfIntrestComponent })));
const DeviceComponent = lazy(() => import('./components/devices/DeviceComponent').then(module => ({ default: module.DeviceComponent })));
const VehicleComponent = lazy(() => import('./components/vehicle/VehicleComponent').then(module => ({ default: module.VehicleComponent })));
const ReportComponent = lazy(() => import('./components/report/ReportComponent').then(module => ({ default: module.ReportComponent })));
const OverSpeedLimitComponent = lazy(() => import('./components/vehicle/OverSpeedLimitComponent').then(module => ({ default: module.OverSpeedLimitComponent })));
const MultipleVehicleComponent = lazy(() => import('./components/Shared/controls/MultipleVehicleComponent').then(module => ({ default: module.MultipleVehicleComponent })));
const ManageGroupComponent = lazy(() => import('./components/manageGroup/ManageGroupComponent').then(module => ({ default: module.ManageGroupComponent })));
const MultipleUserComponent = lazy(() => import('./components/Shared/controls/MultipleUserComponent').then(module => ({ default: module.MultipleUserComponent })));
const VehicleMakeComponent = lazy(() => import('./components/vehicleMake/VehicleMakeComponent').then(module => ({ default: module.VehicleMakeComponent })));
const VehicleModelComponent = lazy(() => import('./components/vehicleModel/VehicleModelComponent').then(module => ({ default: module.VehicleModelComponent })));
const AccountProjectComponent = lazy(() => import('./components/accountProject/AccountProjectComponent').then(module => ({ default: module.AccountProjectComponent })));
const BaseLocationComponent = lazy(() => import('./components/baseLocation/BaseLocationComponent').then(module => ({ default: module.BaseLocationComponent })));
const TransporterComponent = lazy(() => import('./components/transporter/TransporterComponent').then(module => ({ default: module.TransporterComponent })));
const VehicleAlertComponent = lazy(() => import('./components/vehicleAlert/VehicleAlertComponent').then(module => ({ default: module.VehicleAlertComponent })));
const DriversComponent = lazy(() => import('./components/drivers/DriversComponent').then(module => ({ default: module.DriversComponent })));
const UsersComponent = lazy(() => import('./components/users/UsersComponent').then(module => ({ default: module.UsersComponent })));
const VehicleCollectionTypeComponent = lazy(() => import('./components/vehicleCollection/VehicleCollectionTypeComponent').then(module => ({ default: module.VehicleCollectionTypeComponent })));
const FleetTypeComponent = lazy(() => import('./components/fleetType/FleetTypeComponent').then(module => ({ default: module.FleetTypeComponent })));
const RoutesComponent = lazy(() => import('./components/routes/RoutesComponent').then(module => ({ default: module.RoutesComponent })));
const DatagridComponent = lazy(() => import('./components/datagrid/DatagridComponent').then(module => ({ default: module.DatagridComponent })));
const DispatchTripComponent = lazy(() => import('./components/emergency/DispatchTripComponent').then(module => ({ default: module.DispatchTripComponent })));
const JobTripComponent = lazy(() => import('./components/emergency/JobTripComponent').then(module => ({ default: module.JobTripComponent })));
const RawTripComponent = lazy(() => import('./components/emergency/RawTripComponent').then(module => ({ default: module.RawTripComponent })));
const OffRoadTripsComponent = lazy(() => import('./components/emergency/OffRoadTripsComponent').then(module => ({ default: module.OffRoadTripsComponent })));
const UploadOffRoadTripsComponent = lazy(() => import('./components/emergency/UploadOffRoadTripsComponent').then(module => ({ default: module.UploadOffRoadTripsComponent })));
const DashboardComponent = lazy(() => import('./components/dairy/DashboardComponent').then(module => ({ default: module.DashboardComponent })));
const InterStateTripComponent = lazy(() => import('./components/dairy/InterStateTripComponent').then(module => ({ default: module.InterStateTripComponent })));
const DCSBreakdownComponent = lazy(() => import('./components/dairy/DCSBreakdownComponent').then(module => ({ default: module.DCSBreakdownComponent })));
const GenerateTripComponent = lazy(() => import('./components/dairy/GenerateTripComponent').then(module => ({ default: module.GenerateTripComponent })));
const UploadMilkDataComponent = lazy(() => import('./components/dairy/UploadMilkDataComponent').then(module => ({ default: module.UploadMilkDataComponent })));
const UploadTripComponent = lazy(() => import('./components/dairy/UploadTripComponent').then(module => ({ default: module.UploadTripComponent })));
const TripComponent = lazy(() => import('./components/dairy/TripComponent').then(module => ({ default: module.TripComponent })));
const MilkDataComponent = lazy(() => import('./components/dairy/MilkDataComponent').then(module => ({ default: module.MilkDataComponent })));
const RateSheetComponent = lazy(() => import('./components/billing/RateSheetComponent').then(module => ({ default: module.RateSheetComponent })));
const RouteBaseLocationComponent = lazy(() => import('./components/dairy/RouteBaseLocationComponent').then(module => ({ default: module.RouteBaseLocationComponent })));
const RoutePoiComponent = lazy(() => import('./components/routePoi/RoutePoiComponent').then(module => ({ default: module.RoutePoiComponent })));
const RouteVehicleMapping = lazy(() => import('./components/routeVehicle/RouteVehicleMapping').then(module => ({ default: module.RouteVehicleMapping })));
const UserPermissionComponent = lazy(() => import('./components/users/UserPermissionComponent').then(module => ({ default: module.UserPermissionComponent })));
const TripConvertComponent = lazy(() => import('./components/dairy/TripConvertComponent').then(module => ({ default: module.TripConvertComponent })));
const DispatchApiLogComponent = lazy(() => import('./components/dairy/DispatchApiLogComponent').then(module => ({ default: module.DispatchApiLogComponent })));
const BillingComponent = lazy(() => import('./components/billing/BillingComponent').then(module => ({ default: module.BillingComponent })));
const ChangePasswordComponent = lazy(() => import('./components/ChangePasswordComponent').then(module => ({ default: module.ChangePasswordComponent })));
const ResetPasswordComponent = lazy(() => import('./components/ResetPasswordComponent').then(module => ({ default: module.ResetPasswordComponent })));
const EmailSmsSubscriptionComponent = lazy(() => import('./components/emailSmsSubscription/EmailSmsSubscriptionComponent').then(module => ({ default: module.EmailSmsSubscriptionComponent })));
const SenderSettingsComponent = lazy(() => import('./components/emailSmsSubscription/SenderSettingsComponent').then(module => ({ default: module.SenderSettingsComponent })));
const IpAddressWhiteListComponent = lazy(() => import('./components/ipAddressWhiteList/IpAddressWhiteListComponent').then(module => ({ default: module.IpAddressWhiteListComponent })));
const CustomerComponent = lazy(() => import('./components/customer/CustomerComponent').then(module => ({ default: module.CustomerComponent })));
const TransporterUserComponent = lazy(() => import('./components/transporter/TransporterUserComponent').then(module => ({ default: module.TransporterUserComponent })));
const PlantToChillingComponent = lazy(() => import('./components/dairy/PlantToChillingComponent').then(module => ({ default: module.PlantToChillingComponent })));
const SimCardComponent = lazy(() => import('./components/simCard/SimCardComponent').then(module => ({ default: module.SimCardComponent })));
const EditTripComponent = lazy(() => import('./components/dairy/EditTripComponent').then(module => ({ default: module.EditTripComponent })));
const SwapTripComponent = lazy(() => import('./components/dairy/SwapTripComponent').then(module => ({ default: module.SwapTripComponent })));
const CreateTripComponent = lazy(() => import('./components/dairy/CreateTripComponent').then(module => ({ default: module.CreateTripComponent })));
const GeofenceTripComponent = lazy(() => import('./components/geofence/GeofenceTripComponent').then(module => ({ default: module.GeofenceTripComponent })));
const EditGeofenceTripComponent = lazy(() => import('./components/geofence/EditGeofenceTripComponent').then(module => ({ default: module.EditGeofenceTripComponent })));
const ScheduleTripsComponent = lazy(() => import('./components/dairy/ScheduleTripsComponent').then(module => ({ default: module.ScheduleTripsComponent })));
const MultipleRouteVehicleComponent = lazy(() => import('./components/Shared/controls/MultipleRouteVehicleComponent').then(module => ({ default: module.MultipleRouteVehicleComponent })));
const BeaconMasterComponent = lazy(() => import('./components/beaconMaster/BeaconMasterComponent').then(module => ({ default: module.BeaconMasterComponent })));
const YatriApilogComponent = lazy(() => import('./components/train/YatriApilogComponent').then(module => ({ default: module.YatriApilogComponent })));
const BeaconLogComponent = lazy(() => import('./components/train/BeaconLogComponent').then(module => ({ default: module.BeaconLogComponent })));
const TrainMasterComponent = lazy(() => import('./components/train/TrainMasterComponent').then(module => ({ default: module.TrainMasterComponent })));
const TrainTripComponent = lazy(() => import('./components/train/TrainTripComponent').then(module => ({ default: module.TrainTripComponent })));
const RakeLinkWiseComponent = lazy(() => import('./components/train/RakeLinkWiseComponent').then(module => ({ default: module.RakeLinkWiseComponent })));
const BilledTripComponent = lazy(() => import('./components/billing/BilledTripComponent').then(module => ({ default: module.BilledTripComponent })));
const EditBillingComponent = lazy(() => import('./components/billing/EditBillingComponent').then(module => ({ default: module.EditBillingComponent })));
const FindNearestComponent = lazy(() => import('./components/vehicle/FindNearestComponent').then(module => ({ default: module.FindNearestComponent })));
const SlabRateComponent = lazy(() => import('./components/billing/SlabRateComponent').then(module => ({ default: module.SlabRateComponent })));
const OffRoadDashboardComponent = lazy(() => import('./components/emergency/OffRoadDashboardComponent').then(module => ({ default: module.OffRoadDashboardComponent })));
const LiveStatusComponent = lazy(() => import('./components/train/LiveStatusComponent').then(module => ({ default: module.LiveStatusComponent })));
const TrainRunningScheduleComponent = lazy(() => import('./components/train/TrainRunningScheduleComponent').then(module => ({ default: module.TrainRunningScheduleComponent })));
const PlatformHistoryComponent = lazy(() => import('./components/train/PlatformHistoryComponent').then(module => ({ default: module.PlatformHistoryComponent })));
const GatePassComponent = lazy(() => import('./components/gatePass/GatePassComponent').then(module => ({ default: module.GatePassComponent })));
const RedirectUserComponent = lazy(() => import('./components/RedirectUserComponent').then(module => ({ default: module.RedirectUserComponent })));
const GeofenceDashboardComponent = lazy(() => import('./components/emergency/GeofenceDashboardComponent').then(module => ({ default: module.GeofenceDashboardComponent })));
const EdsApiLogComponent = lazy(() => import('./components/emergency/EdsApiLogComponent').then(module => ({ default: module.EdsApiLogComponent })));
const EdsSmsLogComponent = lazy(() => import('./components/emergency/EdsSmsLogComponent').then(module => ({ default: module.EdsSmsLogComponent })));
const GatePassApiLogComponent = lazy(() => import('./components/gatePass/GatePassApiLogComponent').then(module => ({ default: module.GatePassApiLogComponent })));
const NICTripApiLogComponent = lazy(() => import('./components/emergency/NICTripApiLogComponent').then(module => ({ default: module.NICTripApiLogComponent })));
const DispatchTripUploadLogComponent = lazy(() => import('./components/emergency/DispatchTripUploadLogComponent').then(module => ({ default: module.DispatchTripUploadLogComponent })));
const StudentComponent = lazy(() => import('./components/school/StudentComponent').then(module => ({ default: module.StudentComponent })));
const AttendanceComponent = lazy(() => import('./components/school/AttendanceComponent').then(module => ({ default: module.AttendanceComponent })));
const UserSecurityComponent = lazy(() => import('./components/users/UserSecurityComponent').then(module => ({ default: module.UserSecurityComponent })));
const RouteTripHourMappingComponent = lazy(() => import('./components/routeHour/RouteTripHourMappingComponent').then(module => ({ default: module.RouteTripHourMappingComponent })));

export default class App extends Component {
    static displayName = App.name;

    constructor(props) {
        super(props);

        sessionStorage.setItem("REACT_APP_API_BASE_URL", this.getBaseUrl(process.env.REACT_APP_API_BASE_URL));
        sessionStorage.setItem("REACT_APP_TRIPS_API_BASE_URL", this.getBaseUrl(process.env.REACT_APP_TRIPS_API_BASE_URL));
        sessionStorage.setItem("REACT_APP_REPORT_API_BASE_URL", this.getBaseUrl(process.env.REACT_APP_REPORT_API_BASE_URL));

        const mapApiKey = secureLocalStorage.getItem('session_googleMapKey');
        const parentUserName = secureLocalStorage.getItem('session_parentUserName');
        const parentUserId = secureLocalStorage.getItem('session_parentUserId');

        const queryString = require('query-string');
        try {
            var parsed = queryString.parse(window.location.search);
            if (parsed.userguid != undefined && parsed.userguid != "") {
                secureLocalStorage.clear();
                secureLocalStorage.setItem('session_googleMapKey', mapApiKey);
                secureLocalStorage.setItem('session_parentUserName', parentUserName);
                secureLocalStorage.setItem('session_parentUserId', parentUserId);
            }
        } catch (err) { }

        this.state = {
            isAuthorized: false
        }
    }

    async componentDidMount() {
        let isAuthorized = (secureLocalStorage.getItem('session_token') === '' || secureLocalStorage.getItem('session_token') === null) ? false : true;
        this.setState({ isAuthorized: isAuthorized });
        if (isAuthorized) {
            isAuthorized = false;
            const requestParams = getRequestParams('GET');
            const response = await fetch(sessionStorage.getItem("REACT_APP_API_BASE_URL") + 'authentication/IsSessionAlive', requestParams);
            if (response.ok) {
                const data = await response.json();
                if (data !== null && data.isSuccess) {
                    isAuthorized = true;
                }
            }
            this.setState({ isAuthorized: isAuthorized });
        }
    }

    getBaseUrl(baseUrl) {
        if (window.location.hostname.indexOf("localhost") === -1) {
            if (baseUrl.indexOf("track.utwiz.com") !== -1) {
                baseUrl = baseUrl.replace("track.utwiz.com", window.location.hostname);
            } else if (baseUrl.indexOf("zhl.utwiz.com") !== -1) {
                baseUrl = baseUrl.replace("zhl.utwiz.com", window.location.hostname);
            }
        }
        return baseUrl;
    }

    render() {
        const mapApiKey = secureLocalStorage.getItem('session_googleMapKey');

        return (
            <Fragment>
                {
                    this.state.isAuthorized === true ?
                        <AuthorisedLayout>
                            <Suspense fallback={<div>Loading ...</div>}>
                                <Wrapper apiKey={mapApiKey} libraries={["geometry"]}>
                                    <Route exact path='/' component={HomeComponent} />
                                    <Route path='/home' component={HomeComponent} />
                                    <Route path='/fleet-status' component={VehicleStatusComponent} />
                                    <Route path='/vehicle-replay' component={VehicleReplayComponent} />
                                    <Route path='/poi' component={PointOfIntrestComponent} />
                                    {/* <Route path='/vehiclealert' component={VehicleAlertComponent} /> */}
                                    <Route path='/vehiclealert' component={VehicleAlertComponent} />
                                    <Route path='/device' component={DeviceComponent} />
                                    <Route path='/vehicle' component={VehicleComponent} />
                                    <Route path='/report' component={ReportComponent} />
                                    <Route path='/overspeedlimit' component={OverSpeedLimitComponent} />
                                    <Route path='/multipevehicle' component={MultipleVehicleComponent} />
                                    <Route path='/vehicle-group' component={ManageGroupComponent} />
                                    <Route path='/mutipleuser' component={MultipleUserComponent} />
                                    <Route path='/vehiclemake' component={VehicleMakeComponent} />
                                    <Route path='/vehiclemodel' component={VehicleModelComponent} />
                                    <Route path='/accountgroup' component={AccountProjectComponent} />
                                    <Route path='/baseLocation' component={BaseLocationComponent} />
                                    {/* <Route path='/vehiclemodel' component={VehicleModelComponent}/> */}
                                    <Route path='/transporter' component={TransporterComponent} />
                                    <Route path='/drivers' component={DriversComponent} />
                                    <Route path='/users' component={UsersComponent} />
                                    <Route path='/vehiclecollectiontype' component={VehicleCollectionTypeComponent} />
                                    <Route path='/fleetType' component={FleetTypeComponent} />
                                    <Route path='/emergency/jobtrip' component={JobTripComponent} />
                                    <Route path='/emergency/dispatch' component={DispatchTripComponent} />
                                    <Route path='/headerconfiguration' component={DatagridComponent} />
                                    <Route path='/routes' component={RoutesComponent} />
                                    {/*<Route path='/rawtrip' component={RawTripComponent} />*/}
                                    <Route path='/routepoi' component={RoutePoiComponent} />
                                    <Route path='/routevehicle' component={RouteVehicleMapping} />
                                    <Route path='/emergency/offRoadTrips' component={OffRoadTripsComponent} />
                                    <Route path='/emergency/uplaodOffRoadTrips' component={UploadOffRoadTripsComponent} />
                                    <Route path='/emergency/offRoadDashboard' component={OffRoadDashboardComponent} />
                                    <Route path='/emergency/GeofenceDashboard' component={GeofenceDashboardComponent} />
                                    <Route path='/emergency/edsapilog' component={EdsApiLogComponent} />
                                    <Route path='/emergency/smslog' component={EdsSmsLogComponent} />

                                    <Route path='/dairy/dashboard' component={DashboardComponent} />
                                    <Route path='/dairy/trip' component={TripComponent} />
                                    <Route path='/dairy/interstate' component={InterStateTripComponent} />
                                    <Route path='/dairy/dcsbreakdown' component={DCSBreakdownComponent} />
                                    <Route path='/dairy/generatetrip' component={GenerateTripComponent} />
                                    <Route path='/dairy/uploadtrip' component={UploadTripComponent} />
                                    <Route path='/dairy/uploadmilk' component={UploadMilkDataComponent} />  
                                    <Route path='/permission' component={UserPermissionComponent}/>                              
                                    <Route path='/dairy/tripconvert' component={TripConvertComponent} />       
                                    <Route path='/dairy/dispatchApiLog' component={DispatchApiLogComponent} />       
                                    <Route path='/billing/billing' component={BillingComponent} />  
                                    <Route path='/dairy/milkdata' component={ MilkDataComponent} />
                                    <Route path='/billing/ratesheet' component={ RateSheetComponent} /> 
                                    <Route path='/dairy/route-baselocation' component={ RouteBaseLocationComponent} /> 
                                
                                    <Route path='/changepassword' component={ChangePasswordComponent}/>  
                                    <Route path='/emailSmsSubscription/emailSmsSubscription' component={EmailSmsSubscriptionComponent} />  
                                    <Route path='/emailSmsSubscription/senderSettings' component={SenderSettingsComponent} /> 
                                    <Route path='/ipAddressWhiteList' component={IpAddressWhiteListComponent} />                               
                                    <Route path='/customer' component={CustomerComponent} />
                                    <Route path='/transporter-user' component={TransporterUserComponent} />
                                    <Route path='/planttochilling' component={PlantToChillingComponent}/>
                                    <Route path='/simcard' component={SimCardComponent}/>
                                    <Route path='/edittrip' component={EditTripComponent}/>
                                    <Route path='/swapTrip' component={SwapTripComponent}/>
                                    <Route path ='/createtrip' component={CreateTripComponent}/>
                                    <Route path='/geofence/trip' component={GeofenceTripComponent}/>
                                    <Route path='/geofence/edittrip' component={EditGeofenceTripComponent}/>
                                    <Route path ='/dairy/scheduleTrips' component={ScheduleTripsComponent}/>
                                    <Route path='/multipleroutevehicle' component={MultipleRouteVehicleComponent}/>
                                    <Route path='/train/yatriapilog' component={YatriApilogComponent}/>
                                    <Route path='/beacon' component={BeaconMasterComponent}/>
                                    <Route path='/train/beaconlog' component={BeaconLogComponent}/> 
                                    <Route path='/train/master' component={TrainMasterComponent}/> 
                                    <Route path='/train/trips' component={TrainTripComponent}/> 
                                    <Route path='/train/rakelinkwisetrips' component={RakeLinkWiseComponent}/>
                                    <Route path='/billing/billedtrip' component={BilledTripComponent}/>
                                    <Route path='/billing/editbilling' component={EditBillingComponent}/>
                                    <Route path='/vehicles/findnearest' component={FindNearestComponent}/>
                                    <Route path='/billing/slabrate' component={SlabRateComponent}/>
                                    <Route path='/train/live-status' component={LiveStatusComponent}/>
                                    <Route path='/train/runningschedule' component={TrainRunningScheduleComponent}/>
                                    <Route path='/train/platform-history' component={PlatformHistoryComponent}/>
                                    <Route path='/gatepass/trips' component={GatePassComponent}/>
                                    <Route path='/gatepass/apilog' component={GatePassApiLogComponent}/>
                                    <Route path='/redirect' component={LoginComponent} />
                                    <Route path='/devices/rawdata' component={RawTripComponent} />
                                    <Route path='/emergency/nicTripsApiLog' component={NICTripApiLogComponent} />
                                    <Route path='/emergency/uploadLog' component={DispatchTripUploadLogComponent} />

                                    <Route path='/school/students' component={StudentComponent} />
                                    <Route path='/school/attendance' component={AttendanceComponent} />
                                    <Route path='/usersecurity' component={UserSecurityComponent} />
                                    <Route path='/routeTriphour' component={RouteTripHourMappingComponent} />
                                </Wrapper>
                            </Suspense>
                        </AuthorisedLayout>
                        :
                        <Layout>
                            <Suspense fallback={<div>Loading ...</div>}>
                                <Route exact path='/' component={LoginComponent} />
                                <Route path='/login' component={LoginComponent} />
                                <Route path='/redirect' component={RedirectUserComponent} />                            
                                <Route path='/forgotpassword' component={ForgotPasswordComponent} />
                                <Route path='/register' component={RegisterComponent} />
                                <Route path='/resetpassword' component={ResetPasswordComponent} />
                            </Suspense>
                        </Layout>
                }
            </Fragment>
        );
    }
}