import React, { Component, Fragment } from 'react';
import Modal from 'react-modal';
import RadioGroup from 'devextreme-react/radio-group';
import { SelectBox } from 'devextreme-react/select-box';
import { getRequestParams } from '../../helpers/globalfunctions.js';
import Swal from 'sweetalert2';
import  secureLocalStorage  from  "react-secure-storage";

const priorities = ['Close', 'Snooze'];
const ddlSnoozeNotificationDuration = [{ id: 300, name: "5 mins" }, { id: 600, name: "10 mins" }];
export class SnoozedOrClosedVehicleAlertsComponent extends Component {
    static displayName = SnoozedOrClosedVehicleAlertsComponent.name;

    constructor(props) {
        super(props);
        this.state = {
            loadPanelVisible: false,
            alertData: {},
            selectionPriority: priorities[0],

            otherRemarkDiv: true,
            notificationDurationTimeDiv: true,
            closedDetailsDiv: false,
            snoozedDetailsDiv: false,
            snoozedFieldsDiv: false,
            btnCloseNotification: true,
            btnSnoozeNotification: false,
            duration: 0,
            closedRemark: '',
            snoozedRemark: '',

            closeRemarkError: '',
            snoozRamarkError: '',
            durationError: '',

        }
        this.showInfo = this.showInfo.bind(this);
        this.hideInfo = this.hideInfo.bind(this);
        this.changeSelectionPriority = this.changeSelectionPriority.bind(this);
        this.SnoozedOrClosed = this.SnoozedOrClosed.bind(this);
        this.handleInputChange = this.handleInputChange.bind(this);

    }

    componentDidMount() {

    }

    showInfo = () => {
        this.setState({
            popupVisible: true,
        });
    }

    hideInfo = () => {
        this.setState({
            popupVisible: false,
        });
        this.setDefault();
    }

    changeSelectionPriority(e) {
        this.setState({
            selectionPriority: e.value,
        });
        if (e.value == "Close") {
            this.setState({
                otherRemarkDiv: true,
                snoozedFieldsDiv: false,
                btnCloseNotification: true,
                btnSnoozeNotification: false,
                // isbtnCloseDisabled : this.state.alertData.isClosed
            });
        }
        else {
            this.setState({
                otherRemarkDiv: false,
                snoozedFieldsDiv: true,
                btnCloseNotification: false,
                btnSnoozeNotification: true,
                // isbtnSnoozeDisabled : this.state.alertData.isClosed
            });
        }
    }

    handleDurationChange = (e) => {
        this.setState({
            duration: (e.selectedItem != null ? e.selectedItem.id : 0)
        });
    }

    setDefault = () => {
        this.setState({
            alertData: {},
            selectionPriority: priorities[0],
            otherRemarkDiv: true,
            notificationDurationTimeDiv: true,
            closedDetailsDiv: false,
            snoozedDetailsDiv: false,
            snoozedFieldsDiv: false,
            btnCloseNotification: true,
            btnSnoozeNotification: false,
            duration: 0
        });
    }

    getVehicleAlertById = async (id, alertTime) => {
        let date = (alertTime.toString()).replace(/[\W-:+T]/g, '');
        this.setState({ loadPanelVisible: true });
        try {
            const requestParams = getRequestParams('GET');
            const response = await fetch(sessionStorage.getItem("REACT_APP_API_BASE_URL") + 'VehicleAlerts/' + id + '/' + date, requestParams);
            const data = await response.json();
            this.setState({ loadPanelVisible: false });
            if (data.isSuccess) {
                this.setState({
                    alertData: data.payload != null ? data.payload : {},
                    duration: data.payload.snoozeDurationInSeconds,
                    closedDetailsDiv: data.payload.isClosed,
                    snoozedDetailsDiv: data.payload.isSnoozed,
                }, () => {
                    this.showInfo();
                });
            }
            else {
                this.setState({ alertsSettings: [] })
            }

        } catch (err) {
            console.log("Error in " + this.displayName + ".getVehicleAlertById function", err);
            this.setState({ loadPanelVisible: false });
        }
    }

    handleInputChange(event) {
        const target = event.target;
        const value = target.value;
        const name = target.name;
        if (name == "closeRemarkTxt") {
            this.setState(prevState => {
                prevState = JSON.parse(JSON.stringify(this.state.alertData));
                prevState.closedRemark = value;
                return { alertData: prevState }
            })
        }

        if (name == "snoozeRemarkTxt") {
            this.setState(prevState => {
                prevState = JSON.parse(JSON.stringify(this.state.alertData));
                prevState.snoozeRemarks = value;
                return { alertData: prevState }
            })
        }
    }

    SnoozedOrClosed = async (operationType) => {   // operationType 0 = snooze, 1 = close 
        this.setState({ loadPanelVisible: true, snoozRamarkError: '', closedRemark: '', durationError: '' });
        try {
            var request = new Object();
            request.UserId = secureLocalStorage.getItem('session_userId');
            request.Id = this.state.alertData.id;
            request.AlertTime = this.state.alertData.alertTime;
            request.Remarks = operationType == 0 ? this.state.alertData.snoozeRemarks : this.state.alertData.closedRemark;
            request.Duration = this.state.duration;

            let formIsValid = true;
            if (operationType == 0) {
                if (this.state.alertData.snoozeRemarks == '' || this.state.alertData.snoozeRemarks == null) {
                    this.setState({ snoozRamarkError: 'Remark required.' });
                    formIsValid = false;
                }
                if (this.state.duration == '' || this.state.duration == 0) {
                    this.setState({ durationError: 'Duration required.' });
                    formIsValid = false;
                }
            } else {
                if (this.state.alertData.closedRemark == '' || this.state.alertData.closedRemark == null) {
                    this.setState({ closeRemarkError: 'Remark required.' });
                    formIsValid = false;
                }
            }

            if (!formIsValid) {
                return false;
            }

            let alertmesage = this.state.poiData == 0 ? "Are you sure, you want create poi?" : "Are you sure, you want to update poi?";
            let isConfirm = false;
            Swal.fire({
                html: alertmesage,
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Yes',
                allowOutsideClick: false
            }).then((result) => {
                isConfirm = result.isConfirmed;
                if (isConfirm) {
                    this.saveAlert(operationType, request);
                }
            });
        } catch (err) {
            console.log("Error in " + this.displayName + ".SnoozedOrClosed function", err);
            this.setState({ loadPanelVisible: false });
        }
    };

    saveAlert = async (operationType, request) => {
        const requestParams = getRequestParams('POST', request);

        let methodName = operationType == 0 ? 'Snooze' : 'Close';

        const response = await fetch(sessionStorage.getItem("REACT_APP_API_BASE_URL") + "VehicleAlerts/" + methodName, requestParams);
        const data = await response.json();
        this.setState({ loadPanelVisible: false });
        if (data.isSuccess) {
            this.hideInfo();
            Swal.fire(data.message);
            this.props.reloadData();
        }
        else {
            Swal.fire(data.message);
        }
    }

    render() {
        return (
            <Modal ariaHideApp={false}
                isOpen={this.state.popupVisible}
                //style={customStyles}
                className={"react-modal"}
                onAfterOpen={this.handleAssignDataToState}
                onRequestClose={this.handleCloseModal}
                shouldCloseOnOverlayClick={false}
                contentLabel="Example Modal">
                <form method="post" onSubmit={this.handleVehicleSubmit} style={this.state.isSubmited ? { pointerEvents: "none", opacity: "0.4" } : {}}>
                    <div className="modal-header">
                        <h6 className="modal-title">Device Notification Snooze/Closure</h6>
                        <button type="button" className="close" data-dismiss="modal" aria-label="Close" onClick={this.hideInfo}>
                            <span aria-hidden="true">×</span>
                        </button>
                    </div>
                    <div className="modal-body modal-overflow">
                        <div className="row">
                            <div className="col-md-6">
                                <label>Alert time: </label>
                                <label>&nbsp;{this.state.alertData.alertTime}</label>
                            </div>
                        </div>

                        <div className="row">
                            <div className="col-md-6">
                                <label>Vehicle No: </label>
                                <label>&nbsp;{this.state.alertData.vehicleNo}</label>
                            </div>
                            <div className="col-md-6">
                                <label>Place Name: </label>
                                <label>&nbsp;{this.state.alertData.placeName}</label>
                            </div>
                        </div>

                        <div className="row" style={{ display: (this.state.alertData.isRead ? 'block' : 'none') }}>
                            <div className="col-md-6">
                                <label>Read By: </label>
                                <label>&nbsp;{this.state.alertData.readByName}</label>
                            </div>
                            <div className="col-md-6">
                                <label>Read On: </label>
                                <label>&nbsp;{this.state.alertData.readOn}</label>
                            </div>
                        </div>

                        <div className="row" style={{ display: (this.state.alertData.snoozedDetailsDiv ? 'block' : 'none') }}>
                            <div className="col-md-6">
                                <label>Snoozed By: </label>
                                <label>&nbsp;{this.state.alertData.snoozedByName}</label>
                            </div>
                            <div className="col-md-6">
                                <label>Snoozed On: </label>
                                <label>&nbsp;{this.state.alertData.snoozedOn}</label>
                            </div>
                        </div>

                        <div className="row" style={{ display: (this.state.alertData.snoozedDetailsDiv ? 'block' : 'none') }}>
                            <div className="col-md-6">
                                <label>Snoozed Till Time: </label>
                                <label>&nbsp;{this.state.alertData.snoozeDurationInSeconds}</label>
                            </div>
                        </div>

                        <div className="row" style={{ display: (this.state.alertData.closedDetailsDiv ? 'block' : 'none') }}>
                            <div className="col-md-6">
                                <label>Closed By: </label>
                                <label>&nbsp;{this.state.alertData.ClosedByName}</label>
                            </div>
                            <div className="col-md-6">
                                <label>Closed On: </label>
                                <label>&nbsp;{this.state.alertData.ClosedDateTime}</label>
                            </div>
                        </div>

                        <div className="row">
                            <div className="col-md-6">
                                <RadioGroup items={priorities} defaultValue={priorities[0]} layout="horizontal" value={this.state.selectionPriority} onValueChanged={this.changeSelectionPriority} />
                            </div>
                            <div className="col-md-6">
                                <label>Speed: </label>
                                <label>&nbsp;{this.state.alertData.ClosedDateTime}</label>
                            </div>
                        </div>

                        <div className="row" style={{ display: (this.state.otherRemarkDiv ? 'block' : 'none') }}>
                            <div className="col-md-12">
                                <label>Remark(<span style={{ color: '#c60808' }}>*</span>)</label>
                                &nbsp;<textarea value={this.state.alertData.remarks} className="form-control" maxLength="250" name='closeRemarkTxt' onChange={this.handleInputChange} ></textarea>
                                {this.state.closeRemarkError.length > 0 && <span className='error-msg'>{this.state.closeRemarkError}</span>}
                            </div>
                        </div>

                        <div style={{ display: (this.state.snoozedFieldsDiv ? 'block' : 'none') }}>
                            <div className="row">
                                <div style={{ display: (this.state.notificationDurationTimeDiv ? 'block' : 'none') }} className="col-md-6 form-group">
                                    <label>Select Duration(<span style={{ color: '#c60808' }}>*</span>)</label>
                                    <SelectBox
                                        dataSource={ddlSnoozeNotificationDuration}
                                        className="basic-multi-select"
                                        classNamePrefix="select"
                                        placeholder="Select Geofence Type"
                                        name="name"
                                        defaultValue={0}
                                        value={this.state.duration}
                                        displayExpr='name'
                                        valueExpr='id'
                                        onSelectionChanged={this.handleDurationChange}
                                        showClearButton={true}
                                        disabled={false}
                                        searchEnabled={true}
                                        width="100%"
                                    />
                                    {this.state.durationError.length > 0 && <span className='error-msg'>{this.state.durationError}</span>}
                                </div>
                            </div>

                            <div className="row">
                                <div className="col-md-12">
                                    <label>Snooz Remark(<span style={{ color: '#c60808' }}>*</span>)</label>
                                    <textarea value={this.state.alertData.snoozeRemarks} className="form-control" maxLength="250" name='snoozeRemarkTxt' onChange={this.handleInputChange}></textarea>
                                    {this.state.snoozRamarkError.length > 0 && <span className='error-msg'>{this.state.snoozRamarkError}</span>}
                                </div>
                            </div>
                        </div>

                        <div className="row">
                            <button type='button' style={{ display: (this.state.btnSnoozeNotification ? 'block' : 'none'), fontSize: 12 + 'px' }} disabled={this.state.alertData.isSnoozed} className="btn btn-success" onClick={() => this.SnoozedOrClosed(0)}>Snooz Notification</button>&nbsp;
                            <button type='button' style={{ display: (this.state.btnCloseNotification ? 'block' : 'none'), fontSize: 12 + 'px' }} disabled={this.state.alertData.isClosed} className="btn btn-success" onClick={() => this.SnoozedOrClosed(1)}>Close Notification</button>&nbsp;
                            <button type='button' className="btn btn-default" style={{ fontSize: 12 + 'px' }} onClick={() => this.hideInfo()}>Close</button>&nbsp;
                        </div>
                    </div>
                </form>
            </Modal >
        );
    }
}