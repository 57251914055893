import React, { Component, Fragment } from 'react';
import { getRequestParams } from '../../../src/helpers/globalfunctions.js';
import { ChangePasswordComponent } from '../ChangePasswordComponent';
import { ClockComponent } from '../ClockComponent';
import { SelectBox } from 'devextreme-react/select-box';
import  secureLocalStorage  from  "react-secure-storage";

export class TopUserSetupComponent extends Component {
    static displayName = TopUserSetupComponent.name;

    constructor(props) {
        super(props);
        this.state = {
            multipleUserAccess: [],
            childUserId: 0,
            childUserGuid: null,
        }
    }

    componentDidMount() {
        this.getUserMultipleAccountAccess();
    }

    getUserMultipleAccountAccess = async () => {
        try {
            const requestParams = getRequestParams('GET');
            let parentUserId = secureLocalStorage.getItem('session_parentUserId');
            const response = await fetch(sessionStorage.getItem("REACT_APP_API_BASE_URL") + 'Users/MultipleAccountDetails' + '/' + parentUserId, requestParams)
            const data = await response.json();
            this.setState({
                multipleUserAccess: data.payload != null ? data.payload : [],                
            });
        } catch (err) {
            console.log("Error in " + this.displayName + ".getUserMultipleAccountAccess function", err);
        }
    }

    handleLogout = async (e) => {
        e.preventDefault();
        const requestParams = getRequestParams('GET');
        let userId = parseInt(secureLocalStorage.getItem("session_userId"));
        let loginHistoryId = parseInt(secureLocalStorage.getItem("session_LoginHistoryId"));
        const response = await fetch(sessionStorage.getItem("REACT_APP_API_BASE_URL") + 'authentication/logout/' + userId + '/' + loginHistoryId, requestParams);
        secureLocalStorage.clear();
        window.location.href = "/";
    }

    handleValidSwitch = async (parentUserId, userId) => {
        const requestParams = getRequestParams("GET");
        const response = await fetch(sessionStorage.getItem("REACT_APP_API_BASE_URL") + 'Users/ValidSwitch/' + parentUserId + '/' + userId, requestParams);
        const redirectdata = await response.json();        
        return redirectdata.isSuccess;
    }

    handleAccountInputChange = async (data) => {
        if (data.selectedItem != null) {
            let parentUserName = secureLocalStorage.getItem("session_parentUserName");
            let parentUserId = secureLocalStorage.getItem("session_parentUserId");
            
            let retFlag = await this.handleValidSwitch(parentUserId, data.selectedItem.userId)            
            if (retFlag === true) {
                const requestParams = getRequestParams("GET");
                const response = await fetch(sessionStorage.getItem("REACT_APP_API_BASE_URL") + 'RedirectUser/Insert/' + data.selectedItem.userName, requestParams);
                const redirectdata = await response.json();
                if (redirectdata.payload != null) {
                    window.location.href = "/redirect/?userguid=" + redirectdata.payload.token
                }
            }
        }
        else {
            this.setState({
                childUserId: 0,
                childUserGuid: null
            });
        }
    }

    openchangesPasswordModel = (e) => {
        e.preventDefault();
        this.refs.changepasswordcomponent.showModal();
    }

    displayExpr= (item) => {  
        if (item != null)  {
            return item.accountName + " [" + item.userName + "]";
        }
        else {
            return "Select Account";
        }
    }

    handleBackToMainAccount = async () => {
        const requestParams = getRequestParams("GET");
        let parentUserName = secureLocalStorage.getItem("session_parentUserName");
        const response = await fetch(sessionStorage.getItem("REACT_APP_API_BASE_URL") + 'RedirectUser/Insert/' + parentUserName, requestParams);
        const redirectdata = await response.json();
        if (redirectdata.payload != null) {
            window.location.href = "/redirect/?userguid=" + redirectdata.payload.token
        }
    }

    render() {
        return (
            <Fragment>
                <li className={this.state.multipleUserAccess.length > 0 ? "displayBlock" : "displayNone"}>
                    <button type="submit" className="btn btn-success" tabIndex="4" onClick={this.handleBackToMainAccount}>
                        <span title="Back to main account"><i className="far fa-arrow-alt-circle-left"></i></span>                        
                    </button>
                </li>
                <li className="ml-1">
                    <SelectBox
                        dataSource={this.state.multipleUserAccess.length > 0 ? this.state.multipleUserAccess : []}
                        className="basic-multi-select"
                        classNamePrefix="select"
                        placeholder="Select Account"
                        name="userId"
                        defaultValue={0}
                        value={this.state.childUserId}
                        //displayExpr='accountName'
                        displayExpr={this.displayExpr}
                        valueExpr='userId'
                        onSelectionChanged={this.handleAccountInputChange}
                        showClearButton={false}
                        disable={false}
                        visible={this.state.multipleUserAccess.length > 0 ? true : false}
                        searchEnabled={true}
                        width={280}
                    />
                </li>    
                <li className="dropdown mr-0 user-profile ml-1">                                        
                    <a href="#" data-toggle="dropdown" className="dropdown-toggle nav-link-user">
                        <div className="d-sm-inline-block d-lg-inline-block text-white pr-2">{secureLocalStorage.getItem("session_accountname").toUpperCase()}</div>
                        <img alt="image" src="./assets/img/avatar/avatar-1.png" className="rounded-circle mr-1" style={{width: "20px"}} />
                    </a>
                    <div className="dropdown-menu dropdown-list dropdown-menu-right rounded-vlg">
                        <div className="dropdown-header pb-0">
                            Hey {secureLocalStorage.getItem('session_userName')}!
                        </div>
                        <div className="text-center mt-3 mb-3">
                            <figure className="avatar mr-2 avatar-xl">
                                <img src="../assets/img/avatar/avatar-1.png" alt="..." />
                                <i className="avatar-presence online"></i>
                            </figure>
                        </div>
                        <a href="#" className="dropdown-item has-icon" onClick={(e) => { this.openchangesPasswordModel(e) }}>
                            <i className="fas fa-lock-open"></i> Change Password
                        </a>
                        <a href="#" onClick={this.handleLogout} className="dropdown-item has-icon mb-2">
                            <i className="fas fa-sign-out-alt"></i> Logout
                        </a>
                        <div className="dropdown-footer text-center pt-0 pb-2"><ClockComponent /></div>
                    </div>
                </li>
                <ChangePasswordComponent ref="changepasswordcomponent" />
            </Fragment>
        );
    }
}