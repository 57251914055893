export const DateFormat = {
    DateTime24WesternFormat: "MM/dd/yyyy HH:mm",
    DateTime12WesternFormat: "MM/dd/yyyy hh:mm",
    DateTime24WesternLongFormat: "MM/dd/yyyy HH:mm:ss",
    DateWesternFormat: "MM/dd/yyyy",

    DateTime24NorthFormat: "dd/MM/yyyy HH:mm",
    DateTime24NorthFormat01: "DD/MM/yyyy HH:mm",
    DateTime12NorthFormat: "dd/MM/yyyy hh:mm",
    DateTime24NorthLongFormat: "dd/MM/yyyy HH:mm:ss",
    DateNorthFormat: "dd/MM/yyyy",

    Time24Format: "HH:mm",
    Time12Format: "hh:mm",
    Time24FormatLong: "HH:mm:ss",
    Time24FormatLongAMPM: "hh:mm:ss a",

    ApiDateTimeFormat: "yyyy-MM-dd HH:mm:ss"
};

export const ApplicationType = {
    UTPro: 12,
}
export const FeatureActionType = {
    UTWiz: 9
}
export const ParentAccount = {
    UTWiz: 1
};
export const ModuleCode = {
    Self: 1,
    TourOperator: 2,
    CommissionAgent: 3,
    Affiliate: 4,
    TravelAgent: 5,
    Crew: 6,
    Customer: 7,
    Hotel: 8,
    HotelAggregator: 9
};
export const CustomerType = {
    AirlineCrew: 1,
    CustomerService: 2,
    Charter: 3,
    Contracts: 4,
    Hotel: 5,
    VoucherTourOperator: 6,
    TravelAgents: 7,
    Affiliates: 8,
};
export const IndustryType = {
    "DAIRY": "DAIRY",
    "EMERGENCYSERVICE": "EMERGENCYSERVICE",
    "GENERALSERVICE": "GENERALSERVICE",
}
export const TransferType = {
    Dairy: 3
}
export const ServiceType = {
    MilkCollection: 5,
    DairyTransfer: 6,
    InterState: 7,
}
export const DairyTripStatus = {
    CREATED: 901,
    OPEN: 902,
    STARTED: 903,
    COMPLETED: 904,
    CANCELLED: 906
}
export const GatePassTripStatus = {
    CREATED: 1001,
    STARTED: 1002,
    CLOSED: 1003,
    CANCELLED: 1004
}


export const ReportId = {
    ONROAD_UNASSIGNED_JOB: 217,
    VEHICLE_DUTY_TRIP_REPORT: 230,
    ONROAD_OFFROAD_GROUP_REPORT: 231,
    TRIP_BOX_REPORT: 232, 
    TRUCK_OPERATIONAL_DETAILED_REPORT: 233,
    INVALID_GPS_REPORT: 234,
    STOPPAGE_GROUP_REPORT: 235,
    PARKING_REPORT: 236,
    CHRONOLOGY_REPORT: 237,
    TRUCK_OPERATIONAL_SUMMARY_REPORT: 238,
    LATITUDE_LONGITUDE_REPORT: 239,
    DAILY_TRAVEL_SUMMARY: 241,
    DRIVE_BY_DAY : 204,
    POWER_CABLE_STATUS : 246,
    GEOFENCE_GROUP_REPORT : 244,
    OVERSPEED_GROUP_REPORT : 243,
}

export const ReportsName = {
    ONROAD_OFFROAD_GROUP_REPORT: "OnRoadOffRoadGroupReport",
    TRIP_BOX_REPORT: "TripBoxReport",
    CHRONOLOGY_REPORT: "ChronologyReport",
    VEHICLE_DUTY_TRIP_REPORT: "VehicleDutyTripReport",
    ACTIVITY_REPORT : 'Activity',
    KILOMETER_SUMMARY_REPORT : 'KilometerSummary',
    DAILY_VEHICLE_STATUS_REPORT  : 'DailyVehicleStatus',
    BUTTON_PANEL_STATUS_REPORT  : 'ButtonPanelStatus',
    LATITUDE_LONGITUDE_REPORT: "LatitudeLongitudeReport",
    JOB_TRIP_REPORT: "JobTripReport",
    OFF_ROAD_VEHICLE_SUMMARY_REPORT : "OffRoadVehicleSummary",
    LAT_LONG_REPORT : "Lat-longReport",
    JOB_TRIP_HALT_DURATION  : 'JobTripHaltDuration',
    TRIPS_NOT_VISITED_POI : 'TripsNotVisitedPoi',
    INVALID_GPS_REPORT : 'InvalidGPSReport',
    BEACON_DETECTION_REPORT : 'BeaconDetectionReport',
    TRAIN_MAPPING_REPORT : 'TrainMappingReport'
}


export const ButtonPanelEvents = {
    DUTY_TRIP: "DUTY TRIP",
    START_TRIP: "START TRIP",
    ON_SCENE: "ON SCENE",
    ON_BOARD: "ON BOARD",
    HOSPITAL_IN: "HOSPITAL IN",
    HOSPITAL_OUT: "HOSPITAL OUT",
    CLEAR: "CLEAR",
    ON_ROAD: "ON ROAD",
    OFF_ROAD: "OFF ROAD",
    CHRONOLOGY: "CHRONOLOGY",
}