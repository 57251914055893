import jsSHA from "jssha"; // npm install jssha
import React, { Component } from 'react';
import { getRequestParams, isNullOrEmpty, parseBoolean } from '../../src/helpers/globalfunctions.js';
import secureLocalStorage from "react-secure-storage";
import axios from 'axios';

export class LoginComponent extends Component {
    static displayName = LoginComponent.name;

    constructor(props) {
        super(props);
        const queryString = require('query-string');
        var parsed = queryString.parse(this.props.location.search);

        this.state = {
            userName: '', password: '', isSubmited: false, message: '',
            ipAddress: '', latitude: 0, longitude: 0,
            locationId: 0,
            otp: '',
            refNo: 0,
            userSecurity: {},
            errors: {
                userName: '',
                password: '',
                otp: ''
            },
            userToken: this.props.location.search != "" ? parsed.userguid : "",
            zhlUserToken: this.props.location.search != "" ? parsed.zhltoken : "",
            isOtpAuthRequired: false,
            payload: {},
            userContext: {},
            locations: {},
        };
        this.handleInputChange = this.handleInputChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.clearControl = this.clearControl.bind(this);
        this.calcHash = this.calcHash.bind(this);
        this.uuidv4 = this.uuidv4.bind(this);
    }

    clearControl() {
        this.setState({
            userName: '', password: '', isSubmited: false, message: '',
            errors: {
                userName: '',
                password: '',
                otp: ''
            }
        });
    }

    handleInputChange(event) {
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;

        this.setState({
            [name]: value
        });
    }

    handleValidation() {
        let formIsValid = true;
        try {
            let errors = this.state.errors;
            errors.userName = "";
            errors.password = "";
            this.setState({ message: '' });

            if (this.state.userName === '') {
                formIsValid = false;
                errors.userName = "Please enter username.";
            }

            if (this.state.password === '') {
                formIsValid = false;
                errors.password = "Please enter password.";
            }
            this.setState({ errors: errors });
        } catch (err) {
            console.log("Error in " + this.displayName + ".handleValidation function", err);
            formIsValid = false;
        }
        return formIsValid;
    }

    calcHash(value, securityKey) {
        try {
            let shaObj = new jsSHA("SHA-256", "TEXT", { encoding: "UTF8" });
            shaObj.update(value);
            let saltText = shaObj.getHash("HEX");

            let shaObj2 = new jsSHA("SHA-256", "TEXT", { encoding: "UTF8" });
            shaObj2.update(saltText + securityKey);

            return shaObj2.getHash("HEX");
        } catch (err) {
            console.log("Error in " + this.displayName + ".calcHash function", err);
        }
    }

    uuidv4() {
        return '00-0-4-1-000'.replace(/[^-]/g,
            s => ((Math.random() + ~~s) * 0x10000 >> s).toString(16).padStart(4, '0')
        );
    }

    async getRedirectUser() {
        var request = new Object();
        request.Token = this.state.userToken;
        const requestParams = getRequestParams("POST", request);
        const response = await fetch(sessionStorage.getItem("REACT_APP_API_BASE_URL") + 'RedirectUser', requestParams);
        const data = await response.json();
        if (data.payload != null) {
            this.setState({ userName: data.payload });
            this.login(true);
        } else {
            this.setState({ userToken: '', message: "Unauthorised User." })
        }
    }

    async componentDidMount() {
        try {
            if (this.state.userToken !== undefined && this.state.userToken !== "") {
                this.getRedirectUser();
            }
            else if (this.state.zhlUserToken !== undefined && this.state.zhlUserToken !== "") {
                this.getUserByToken();
            }

            navigator.geolocation.getCurrentPosition(position => {
                this.setState({
                    latitude: position.coords.latitude,
                    longitude: position.coords.longitude
                });
            });

            var response = await fetch(sessionStorage.getItem("REACT_APP_API_BASE_URL") + "authentication/GetIp", { method: 'GET' });
            var data = await response.json();
            if (data != null && data.isSuccess) {
                this.setState({ ipAddress: data.message });
            }
        } catch (err) {
            console.log("Error in " + this.displayName + ".componentDidMount function", err);
        }
    }

    getUserByToken = async () => {
        let url = 'http://115.242.245.147/punjab108/api.php?token_id=' + this.state.zhlUserToken;
        //let url = sessionStorage.getItem("REACT_APP_API_BASE_URL") + 'Heartbeat/zhlsso?token_id=' + this.state.zhlUserToken;
        const requestParams = getRequestParams("GET");
        const response = await fetch(url, requestParams);
        const data = await response.json();
        if (data != null) {
            if (data.userName != null || data.userName != '') {
                await this.getTokenByUserName(data.userName);
            }
            else {
                this.setState({ userToken: '', message: "Unauthorised User." })
            }
        } else {
            this.setState({ userToken: '', message: "Unauthorised User." })
        }
    }

    getTokenByUserName = async (userName) => {
        const requestParams = getRequestParams("GET");
        const response = await fetch(sessionStorage.getItem("REACT_APP_API_BASE_URL") + 'RedirectUser/Insert/' + userName, requestParams);
        const data = await response.json();
        if (data.payload != null) {
            if (data.payload.token !== undefined && data.payload.token !== "") {
                this.setState({ userToken: data.payload.token }, () => {
                    this.getRedirectUser();
                });
            } else {
                this.setState({ userToken: '', message: "Unauthorised User." })
            }
        }
        else {
            this.setState({ userToken: '', message: "Unauthorised User." })
        }
    }

    handleOtpAuthentication = async () => {
        try {
            var request = new Object();
            request.UserName = this.state.userName;
            request.UserId = this.state.userSecurity.userId;
            request.AccountId = this.state.userSecurity.accountId;
            request.LocationId = this.state.locationId;
            request.SenderSettingId = this.state.userSecurity.senderSettingsId;
            request.EmailId = this.state.userSecurity.emailId;
            request.MobileNo = this.state.userSecurity.mobileNo;
            request.IpAddress = this.state.ipAddress;
            request.Latitude = this.state.latitude;
            request.Longitude = this.state.longitude;
            request.AppTypeId = 13;
            request.OtpTypeId = 1;
            const requestParams = getRequestParams('POST', request);
            this.setState({ isSubmited: true });
            const response = await fetch(sessionStorage.getItem("REACT_APP_API_BASE_URL") + 'authentication/generate-otp', requestParams);
            const data = await response.json();
            if (data != null) {
                if (data.isSuccess) {
                    this.setState({ isSubmited: false, isOtpAuthRequired: true, refNo: data.recordId });
                }
                else {
                    this.setState({ isSubmited: false, isOtpAuthRequired: false });
                }
            }

        } catch (err) {
            console.log("Error in " + this.displayName + ".handleOtpAuthentication", err);
        }
    }

    handleVerifyOtp = async () => {
        try {
            let errors = this.state.errors;
            errors.otp = "";
            if (this.state.otp == '') {
                errors.otp = "Please enter OTP";
                this.setState({ errors: errors });
                return false;
            }

            var request = new Object();
            request.UserId = this.state.userSecurity.userId;
            request.AccountId = this.state.userSecurity.accountId;
            request.LocationId = this.state.locationId;
            request.ReferenceNo = this.state.refNo;
            request.Otp = this.state.otp;
            request.AppTypeId = 13;
            const requestParams = getRequestParams('POST', request);
            this.setState({ isSubmited: true });
            const response = await fetch(sessionStorage.getItem("REACT_APP_API_BASE_URL") + 'authentication/verify-otp', requestParams);
            const data = await response.json();
            this.setState({ isSubmited: false });
            if (data != null) {
                if (data.isSuccess) {
                    this.setSession(false);
                }
                else {
                    let errors = this.state.errors;
                    errors.otp = data.message;
                    this.setState({ errors: errors });
                }
            }

        } catch (err) {
            this.setState({ isSubmited: false });
            console.log("Error in " + this.displayName + ".handleOtpAuthentication", err);
        }
    }

    async login(redirectFlag) {
        try {
            let passwordHash = "";
            var token = new Object();
            token.UserName = this.state.userName;
            const tokenRequestParams = getRequestParams('POST', token);
            //console.log(sessionStorage.getItem("REACT_APP_API_BASE_URL"));

            if (this.state.userToken === "") {
                const tokenResponse = await fetch(sessionStorage.getItem("REACT_APP_API_BASE_URL") + 'authentication/password-token-request', tokenRequestParams);
                const tokenData = await tokenResponse.json();

                if (tokenData.isSuccess) {
                    passwordHash = this.calcHash(this.state.password, tokenData.payload.token);
                } else {
                    this.setState({ isSubmited: false, message: tokenData.message });
                    return;
                }
            }

            var request = new Object();
            request.UserName = this.state.userName;
            request.Password = this.state.userToken != "" ? this.state.userToken : passwordHash;
            request.IpAddress = this.state.ipAddress;
            request.Latitude = this.state.latitude;
            request.Longitude = this.state.longitude;
            request.IsAdminUser = false;
            const requestParams = getRequestParams('POST', request);
            const response = await fetch(sessionStorage.getItem("REACT_APP_API_BASE_URL") + 'authentication', requestParams);
            const data = await response.json();
            if (data.isSuccess) {
                secureLocalStorage.setItem("session_token", data.token);
                let userContext = data.payload.userContext;
                let locations = data.payload.userDefaultLocations;
                this.setState({ userContext: userContext, locations: locations, payload: data.payload });
                let accountId = userContext.accountId;
                let userId = userContext.userId;

                //getUserSecurity
                try {
                    const requestParams = getRequestParams('GET');
                    const response = await fetch(sessionStorage.getItem("REACT_APP_API_BASE_URL") + 'UserSecurity/' + accountId + '/' + userId, requestParams);
                    const data = await response.json();
                    if (data != null && data.payload != null) {
                        let userSecurity = data.payload[0];
                        if (userSecurity.enableSecurity && userSecurity.otpAuthentication) {
                            this.setState({ userSecurity: userSecurity, locationId: locations[0].locationId }, () => {
                                this.handleOtpAuthentication();
                            });
                        }
                        else {
                            this.setSession(redirectFlag);
                        }
                    }
                    else {
                        this.setSession(redirectFlag);
                    }

                } catch (err) {
                    console.log("Error in " + this.displayName + ".getUserSecurity Api", err);
                    this.setSession(redirectFlag);
                }

            }
            else {
                this.setState({ isSubmited: false, message: data.message, userToken: '' });
            }
        } catch (err) {
            console.log("Error in " + this.displayName + ".componentDidMount function", err);
        }
    }

    async setSession(redirectFlag) {
        let payload = this.state.payload;
        let userContext = this.state.userContext;
        let locations = this.state.locations;

        let mapApiKey = "AIzaSyB2__GLNvcvr70qg-p6lvN0O9H9XLhAU00";
        let mapType = "Google";
        let newDate = new Date();
        let currentDay = newDate.getDate();


        try {
            const requestParams = getRequestParams('GET');
            const response = await fetch(sessionStorage.getItem("REACT_APP_API_BASE_URL") + 'Account/GetMapKey/' + userContext.accountId + "/" + mapType + "/" + currentDay, requestParams);
            const mapKeyData = await response.json();
            if (mapKeyData.isSuccess && mapKeyData.payload != null && mapKeyData.payload != undefined) {
                mapApiKey = mapKeyData.payload;
            }
        } catch (err) {
            console.log("Error in " + this.displayName + ".MapKey Api", err);
        }

        if (!redirectFlag) {
            secureLocalStorage.setItem("session_parentUserName", userContext.userName);
            secureLocalStorage.setItem("session_parentUserId", userContext.userId);
        }

        secureLocalStorage.setItem("session_googleMapKey", mapApiKey);
        secureLocalStorage.setItem("session_userId", userContext.userId);
        secureLocalStorage.setItem("session_userName", userContext.userName);
        secureLocalStorage.setItem("session_name", userContext.fullName);
        secureLocalStorage.setItem("session_email", userContext.email);
        secureLocalStorage.setItem("session_accountId", userContext.accountId);
        secureLocalStorage.setItem("session_accountname", userContext.accountName);
        secureLocalStorage.setItem("session_accountcode", userContext.accountCode);
        secureLocalStorage.setItem("session_locationId", locations[0].locationId);
        secureLocalStorage.setItem("session_locationName", locations[0].locationName);
        secureLocalStorage.setItem("session_timezoneName", locations[0].timezoneName);
        secureLocalStorage.setItem("session_timezoneCode", locations[0].timezoneCode);
        secureLocalStorage.setItem("session_uTCOffset", locations[0].uTCOffset);
        secureLocalStorage.setItem("session_isDayLightApply", locations[0].isDayLightApply);
        secureLocalStorage.setItem("session_dlStartTime", locations[0].dlStartTime);
        secureLocalStorage.setItem("session_dlEndTime", locations[0].dlEndTime);
        secureLocalStorage.setItem("session_dlTimeDifference", locations[0].dlTimeDifference);

        secureLocalStorage.setItem("session_minutesDifference", locations[0].minutesDifference);
        secureLocalStorage.setItem("session_userTypeId", userContext.userTypeId);
        secureLocalStorage.setItem("session_userTypeName", userContext.userTypeName);
        secureLocalStorage.setItem("session_moduleId", userContext.moduleId);
        secureLocalStorage.setItem("session_fileGuid", userContext.fileGuid);
        secureLocalStorage.setItem("session_culturecode", "en-US");
        secureLocalStorage.setItem("session_accountlegalname", userContext.accountLegalName);
        secureLocalStorage.setItem("session_accountaddress", userContext.accountAddress);
        secureLocalStorage.setItem("session_accountwebaddress", userContext.accountWebAddress);

        secureLocalStorage.setItem("session_accountEmail", userContext.accountEmail);
        secureLocalStorage.setItem("session_accountCountryCode", userContext.accountCountryCode);
        secureLocalStorage.setItem("session_accountPhone", userContext.accountPhone);
        secureLocalStorage.setItem("session_accountMobile", userContext.accountMobile);
        secureLocalStorage.setItem("session_accountState", userContext.accountState);
        secureLocalStorage.setItem("session_accountCity", userContext.accountCity);
        secureLocalStorage.setItem("session_accountCountry", userContext.accountCountry);
        secureLocalStorage.setItem("session_skinId", userContext.skinId);
        secureLocalStorage.setItem("session_skinName", userContext.skinName);
        secureLocalStorage.setItem("session_cssSkinName", userContext.cssSkinName);
        secureLocalStorage.setItem("session_accountLogo", userContext.logo);
        secureLocalStorage.setItem("session_accountLogoName", userContext.logoName);
        secureLocalStorage.setItem("session_accountLogoSize", userContext.logoSize);
        secureLocalStorage.setItem("session_accountLogoExt", userContext.logoExt);
        secureLocalStorage.setItem("session_dashboardName", payload.userDefaultDashboard[0].dashboardName);
        secureLocalStorage.setItem("session_sessionId", payload.sessionId);

        secureLocalStorage.setItem("session_industryTypeId", parseInt(payload.accountIndustryType[0].id));
        secureLocalStorage.setItem("session_industryType", payload.accountIndustryType[0].name);
        secureLocalStorage.setItem("session_planId", userContext.planId);

        secureLocalStorage.setItem("session_dateRangeInMilkUpload", parseBoolean(userContext.dateRangeInMilkUpload));
        secureLocalStorage.setItem("session_scheduleTripsRouteTimewise", parseBoolean(userContext.scheduleTripsRouteTimewise));
        secureLocalStorage.setItem("session_enableOverSpeedVehicleInPoiFeature", parseBoolean(userContext.enableOverSpeedVehicleInPoiFeature));
        secureLocalStorage.setItem("session_applicableBuffCowMilkLogic", parseBoolean(userContext.applicableBuffCowMilkLogic));

        sessionStorage.setItem("session_PageName", "Map");
        secureLocalStorage.setItem("session_LoginHistoryId", userContext.loginHistoryId);

        secureLocalStorage.setItem("session_ShowOnOffRoadDashboard", parseBoolean(locations[0].showOnOffRoadDashboard));
        secureLocalStorage.setItem("session_ShowOnOffRoadFilter", parseBoolean(locations[0].showOnOffRoadFilter));

        secureLocalStorage.setItem("session_ameyoUserId", payload.ameyoUserId);
        secureLocalStorage.setItem("session_ameyoApiUrl", payload.ameyoApiUrl);
        secureLocalStorage.setItem("session_ameyoHashKey", payload.ameyoHashKey);
        secureLocalStorage.setItem("session_ameyoCampaignId", payload.ameyoCampaignId);
        secureLocalStorage.setItem("session_ameyoIsActive", parseBoolean(payload.ameyoIsActive));
        secureLocalStorage.setItem("session_consumerId", userContext.consumerId);

        secureLocalStorage.setItem("session_slabwiseRateIsApplicable", parseBoolean(userContext.slabwiseRateIsApplicable));
        secureLocalStorage.setItem("session_fatSnfLossIsApplicable", parseBoolean(userContext.fatSnfLossIsApplicable));
        secureLocalStorage.setItem("session_transferTransRatesApplicable", parseBoolean(userContext.transferTransRatesApplicable));
        secureLocalStorage.setItem("session_allowEditBillingRevisedKm", parseBoolean(locations[0].allowEditBillingRevisedKm));
        secureLocalStorage.setItem("session_allowVehicleMultiRoute", parseBoolean(locations[0].allowVehicleMultiRoute));

        let trackingWebSocketBaseUrl = locations[0].trackingWebSocketBaseUrl;
        let location_upates_wsurl = isNullOrEmpty(trackingWebSocketBaseUrl) ? process.env.REACT_APP_LOCATION_UPATES_WSURL : trackingWebSocketBaseUrl + 'location-updates';
        let event_upates_wsurl = isNullOrEmpty(trackingWebSocketBaseUrl) ? process.env.REACT_APP_EVENT_UPATES_WSURL : trackingWebSocketBaseUrl + 'event-updates';
        let trip_status_events_wsurl = isNullOrEmpty(trackingWebSocketBaseUrl) ? process.env.REACT_APP_TRIP_STATUS_EVENTS_WSURL : trackingWebSocketBaseUrl + 'trip-status-events';
        secureLocalStorage.setItem("session_locationUpatesWsurl", location_upates_wsurl);
        secureLocalStorage.setItem("session_eventUpatesWsurl", event_upates_wsurl);
        secureLocalStorage.setItem("session_tripStatusEventsWsurl", trip_status_events_wsurl);

        let services = payload.accountServiceTypes;
        let isTrainServiceAccount = services !== null && services !== undefined && services.length > 0 && services.filter((item) => item.name == "TRAIN TRIP").length > 0 ? true : false;
        secureLocalStorage.setItem("session_isTrainServiceAccount", isTrainServiceAccount);

        await this.storeVehicleListToCache();

        window.location.href = "/";
        this.setState({ isSubmited: false, message: '', userToken: '' });
    } catch(err) {
        console.log("Error in " + this.displayName + ".componentDidMount function", err);
        this.setState({ isSubmited: false, message: '', userToken: '' });
    }

    handleSubmit = async (e) => {
        try {
            e.preventDefault();
            if (this.handleValidation()) {
                this.setState({ isSubmited: true });
                await this.login(false);
            }
        } catch (err) {
            console.log("Error in " + this.displayName + ".handleSubmit function", err);
            this.setState({ isSubmited: false, message: "Oops something went worng !!!" });
        }
    }

    storeVehicleListToCache = async (e) => {
        try {
            const requestParams = getRequestParams('GET');

            let accountId = secureLocalStorage.getItem('session_accountId');
            let locationId = secureLocalStorage.getItem('session_locationId');
            let userId = secureLocalStorage.getItem('session_userId');

            const response = await fetch(sessionStorage.getItem("REACT_APP_API_BASE_URL") + 'Vehicles/DropdownList/' + userId + "/" + accountId + "/" + locationId, requestParams);
            const data = await response.json();
            if (data.isSuccess) {
                secureLocalStorage.setItem("session_vehicleList", JSON.stringify(data.payload));
            } else {
                secureLocalStorage.setItem("session_vehicleList", "[]");
            }
        } catch (err) {
            console.log("Error in " + this.displayName + ".storeVehicleListToCache function", err);
        }
    }

    handleBackToLogin = async (e) => {
        e.preventDefault();
        secureLocalStorage.clear();
        window.location.href = "/";
    }


    render() {
        return (
            <div>
                <div className={this.state.userToken === "" ? "displayNone" : "displayBlock redirectloader"}>
                    <span title="In-process"><i className="fas fa-spinner fa-spin"></i> Redirecting...</span>
                </div>
                <section className={this.state.userToken === "" ? "displayBlock section" : "displayNone section"}>
                    <div className="d-flex flex-wrap align-items-stretch login">
                        <div className="col-lg-3 col-md-6 col-12 min-vh-100 bg-white">
                            <div className="p-4 m-3">
                                <div className="text-center"><img src="../assets/img/logo_sst_utwiz2.png" alt="logo" width="300" className="mb-5 mt-2" />
                                    <h4 className="text-dark font-weight-normal">Welcome to <span className="font-weight-bold">SSTracking</span></h4>
                                    <p className="text-muted">Before you get started, you must login.</p></div>
                                <div hidden={this.state.isOtpAuthRequired}>
                                    <form method="POST" action="#" className="needs-validation" noValidate="">
                                        <div className="form-group">
                                            <label htmlFor="email">Username</label>
                                            <input type="text" className="form-control" name="userName" maxLength={50} tabIndex="1" value={this.state.userName} onChange={this.handleInputChange} required autoFocus />
                                            <div className="error invalid-feedback">
                                                {this.state.errors["userName"].length > 0 && <span className='error-msg'>{this.state.errors["userName"]}</span>}
                                            </div>
                                        </div>
                                        <div className="form-group">
                                            <div className="d-block">
                                                <label htmlFor="password" className="control-label">Password</label>
                                            </div>
                                            <input id="password" type="password" className="form-control" maxLength={50} name="password" value={this.state.password} onChange={this.handleInputChange} tabIndex="2" required />
                                            <div className="error invalid-feedback">
                                                {this.state.errors["password"].length > 0 && <span className='error-msg'>{this.state.errors["password"]}</span>}
                                                {this.state.message.length > 0 && <span className='error-msg'>{this.state.message}</span>}
                                            </div>
                                        </div>
                                        {/* <div className="form-group">
                                        <div className="custom-control custom-checkbox">
                                            <input type="checkbox" name="remember" className="custom-control-input" tabIndex="3" id="remember-me" />
                                            <label className="custom-control-label" for="remember-me">Remember Me</label>
                                        </div>
                                    </div>
                                    <div className="form-group text-right">
                                        <a href="/forgotpassword" className="float-left mt-3">
                                            Forgot Password?
                                        </a>
                                        <button type="submit" className="btn btn-primary btn-lg btn-icon icon-right" tabindex="4" onClick={this.handleSubmit}>
                                            {this.state.isSubmited && <span title="Login"><i className="fas fa-spinner fa-spin"></i> Login</span>}
                                            {!this.state.isSubmited && <span title="Login"><i className="fas fa-sign-in-alt"></i> Login</span>}
                                        </button>
                                    </div> */}

                                        <div className="form-group text-right">
                                            <div className="form-group float-left">
                                                <div className="custom-control custom-checkbox ">
                                                    <input type="checkbox" name="remember" className="custom-control-input" tabIndex="3" id="remember-me" />
                                                    <label className="custom-control-label" htmlFor="remember-me">Remember Me</label>
                                                </div>
                                            </div>
                                            <button type="submit" className="btn btn-primary icon-right" tabIndex="4" onClick={this.handleSubmit}>
                                                {this.state.isSubmited && <span title="Login"><i className="fas fa-spinner fa-spin"></i>&nbsp;&nbsp;Validating...</span>}
                                                {!this.state.isSubmited && <span title="Login"><i className="fas fa-sign-in-alt"></i>&nbsp;&nbsp;Login</span>}
                                            </button>
                                        </div>

                                        <div className="mt-3">
                                            <a href="/forgotpassword" className="mt-3">
                                                Need a help, logging in please click here.
                                            </a>
                                        </div>
                                    </form>
                                </div>
                                <div hidden={!this.state.isOtpAuthRequired}>
                                    <div className="form-group">
                                        <label htmlFor="email">OTP</label>
                                        <input type="text" className="form-control" name="otp" maxLength={50} tabIndex="1" value={this.state.otp} onChange={this.handleInputChange} required autoFocus />
                                        <div className="error invalid-feedback">
                                            {this.state.errors["otp"].length > 0 && <span className='error-msg'>{this.state.errors["otp"]}</span>}
                                        </div>
                                    </div>
                                    {/* <div className="form-group text-right">
                                        <button type="submit" className="btn btn-primary icon-right" tabIndex="4" onClick={this.handleVerifyOtp}>Verify</button>
                                    </div> */}

                                    <button type="submit" className="btn btn-primary icon-right" tabIndex="4" onClick={this.handleVerifyOtp}>
                                        {this.state.isSubmited && <span title="Login"><i className="fas fa-spinner fa-spin"></i>&nbsp;&nbsp;Validating...</span>}
                                        {!this.state.isSubmited && <span title="Login"><i className="fas fa-sign-in-alt"></i>&nbsp;&nbsp;Verify</span>}
                                    </button>

                                    <div className="float-right">
                                        <a style={{ cursor: 'pointer' }} onClick={this.handleBackToLogin} className="text-small">Back to Login </a>
                                    </div>

                                </div>
                                <div className="text-center mt-5 text-small login-footer">
                                    <div className="mb-2">
                                        Powered by <a href="http://www.plexitech.com/"><img src="../assets/img/logo-plexitech2.png" alt="logo" width="150" /></a>
                                    </div>
                                    <div className="copyright">&copy; Copyright 2022 PlexiTech. <a href="http://www.sstracking.com/PrivacyPolicy">
                                        Privacy Policy</a> <div className="bullet"></div><a href="http://www.sstracking.com/TermsandConditions">Terms of Service</a></div>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-9 col-12 min-vh-100 background-walk-y position-relative overlay-gradient-bottom" style={{ "backgroundImage": "url(../assets/img/login-banner.jpg)" }}>
                            <div className="absolute-bottom-left index-2">
                                <div className="text-white p-5 pb-2 color-overlay">
                                    <div className="pb-3">
                                        <h1 className="mb-2 display-5 font-weight-bold">Fleet & Mobile<br />Resource Management</h1><br />
                                        <h6 className="font-weight-normal">
                                            <i className="fas fa-headset"></i> Support - From 10:00 am to 6:00 pm. Call 022 42129000
                                        </h6>
                                        <h6 className="font-weight-normal">
                                            <i className="fas fa-globe"></i> Nationwide - Go ahead with the BEST! All India sales and service network.
                                        </h6>
                                        <h6 className="font-weight-normal mb-5 ">
                                            <i className="fas fa-tint"></i> Saving - Guaranteed fuel savings. Calculate how much you will save with us!
                                        </h6>
                                        <h6 className="font-weight-normal ">
                                            Get Our App! - Our mobile application for Android and iOS phones<br />gives you flexibility and control on the go.
                                        </h6>
                                        <a href="https://play.google.com/store/apps/details?id=com.silverstar.tracking">
                                            <img src="../assets/img/androidStore.png" alt="Google Play" /></a>
                                        <a href="https://apps.apple.com/in/app/sstracking/id1507947554">
                                            <img src="../assets/img/iosStore.png" alt="App Store" /></a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
        );
    }
}