import React, { Component } from 'react';
import { getRequestParams, formatDate } from '../helpers/globalfunctions.js';
import  secureLocalStorage  from  "react-secure-storage";

export class ClockComponent extends React.Component {
    static displayName = ClockComponent.name;

    constructor(props) {
        super(props);
        this.state = {
            currentlocationdate: formatDate(new Date(), "MM/dd/yyyy hh:mm:ss"),
            currentlocationdatedisplay: formatDate(new Date(), "dd/MM/yyyy hh:mm:ss"),
        }

        this.getLocationTime = this.getLocationTime.bind(this);
        this.getEstOffset = this.getEstOffset.bind(this);
        this.getEstOffset1 = this.getEstOffset1.bind(this);
    }

    componentDidMount() {
        this.getLocationTime();
        this.interval = setInterval(this.getLocationTime, 1000);
        //this.getLocationTime();
    }

    componentWillUnmount() {
        // fix Warning: Can't perform a React state update on an unmounted component
        this.setState = (state, callback) => {
            return;
        };
    }

    getLocationTime() {
        try {
            let offset = parseInt(secureLocalStorage.getItem("session_minutesDifference"));
            let isDayLightApply = secureLocalStorage.getItem("session_isDayLightApply") === 'true';
            let dLStartDateTime = formatDate(secureLocalStorage.getItem("session_dlStartTime"), "MM/dd/yyyy hh:mm:ss");
            let dLEndDateTime = formatDate(secureLocalStorage.getItem("session_dlEndTime"), "MM/dd/yyyy hh:mm:ss");
            let dLTimeDifference = parseInt(secureLocalStorage.getItem("session_dlTimeDifference"));

            let currentDate = new Date();
            var istDateTime = new Date(currentDate.getTime() + (60000 * (currentDate.getTimezoneOffset() + offset)));
            //const estOffset = this.getEstOffset(istDateTime);

            if (isDayLightApply === "true") {
                if (formatDate(currentDate, "MM/dd/yyyy hh:mm:ss") >= dLStartDateTime && formatDate(currentDate, "MM/dd/yyyy hh:mm:ss") <= dLEndDateTime) {
                    istDateTime = new Date(istDateTime.getTime() + (60000 * dLTimeDifference));
                }
            }

            let locationdate = formatDate(istDateTime, "MM/dd/yyyy hh:mm:ss");
            secureLocalStorage.setItem('session_locationTime', locationdate);
            let currentlocationdatedisplay = formatDate(istDateTime, "dd/MM/yyyy hh:mm:ss");
            this.setState({ currentlocationdate: locationdate, currentlocationdatedisplay: currentlocationdatedisplay });

            //const d = new Date();
            //const localTime = d.getTime();
            ////const localOffset = d.getTimezoneOffset() * 60 * 1000;
            //const localOffset = offset * 60 * 1000;
            //const utcTime = localTime + localOffset;
            //const estOffset1 = this.getEstOffset1();
            //const usa = utcTime + (60 * 60 * 1000 * estOffset1);

            //// convert msec value to date string
            //const nd = new Date(usa)
            //let locationdate1 = formatDate(nd, "MM/dd/yyyy hh:mm:ss");
            //secureLocalStorage.setItem('session_locationTime', locationdate1);
            //this.setState({ currentlocationdate: locationdate1 });            

        } catch (err) {
            console.log("Error in " + this.displayName + ".getLocationTime function", err);
        }
    }

    getEstOffset1() {
        try {
            const stdTimezoneOffset = () => {
                var jan = new Date(0, 1)
                var jul = new Date(6, 1)
                return Math.max(jan.getTimezoneOffset(), jul.getTimezoneOffset())
            }

            var today = new Date()

            const isDstObserved = (today) => {
                return today.getTimezoneOffset() < stdTimezoneOffset()
            }

            if (isDstObserved(today)) {
                return -4
            } else {
                return -5
            }
        } catch (err) {
            console.log("Error in " + this.displayName + ".getEstOffset1 function", err);
        }
    }

    getEstOffset(locDateTime) {
        try {
            const stdTimezoneOffset = () => {
                var jan = new Date(0, 1)
                var jul = new Date(6, 1)
                return Math.max(jan.getTimezoneOffset(), jul.getTimezoneOffset())
            }

            var today = new Date(locDateTime);

            const isDstObserved = (today) => {
                return today.getTimezoneOffset() < stdTimezoneOffset()
            }

            if (isDstObserved(today)) {
                return -4
            } else {
                return -5
            }
        } catch (err) {
            console.log("Error in " + this.displayName + ".getEstOffset function", err);
        }
    }

    render() {
        return (
            <div className="clock">
                <div>{this.state.currentlocationdatedisplay}</div>
            </div>
        );
    }
}