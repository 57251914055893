import React, { Component, Fragment } from 'react';
import { SideBarComponent } from '../components/SideBarComponent';
import { NavItem, NavLink } from 'reactstrap';
import { Link } from 'react-router-dom';
import { w3cwebsocket as W3CWebSocket } from "websocket";
import { ToastContainer, toast } from 'react-toastify';
import IdleTimer from 'react-idle-timer' // npm i react-idle-timer
import 'react-toastify/dist/ReactToastify.css';
import { getRequestParams } from '../../src/helpers/globalfunctions.js';
import { SnoozedOrClosedVehicleAlertsComponent } from '../components/Shared/SnoozedOrClosedVehicleAlertsComponent';
import { TopUserSetupComponent } from '../components/Shared/TopUserSetupComponent';
import { TopBarComponent } from './TopBarComponent';
import Swal from 'sweetalert2';
import  secureLocalStorage  from  "react-secure-storage";

export class AuthorisedLayout extends Component {
    static displayName = AuthorisedLayout.name;

    constructor(props) {
        super(props);
        this.idleTimer = null;
        this.timeout = 3600000;
        this.sessionTimeoutSec = 15000;
        this.toastId = React.createRef();
        this.state = {
            alertsSettings: [],            
            alertData: {},
            deviceObj: {},
            isReadable: false,
            vehicleList: [],
            eventUpatesWsurl : secureLocalStorage.getItem("session_eventUpatesWsurl")
        }
        this.client = null;
        this.authWebSocketInterval = null;
        this.sessionValidateInterval = null;
    }

    componentDidMount() {
        window.authComponentUnloading = false;
        this.getAccountAlertSettings();
        this.getVehicleDropdown();
        document.addEventListener("mousemove", this.handleMouseMove, false);
        document.addEventListener("keypress", this.handleKeyPress, false);
        setTimeout(() => { this.setupWebSocket(); }, 30000);
        this.sessionValidateInterval = setTimeout(() => { this.handleValidateSession(); }, this.sessionTimeoutSec);

        // setInterval(() => {   //for testing
        //     let eventItem = {
        //         eventTime: "2022-01-12 15:24:45",
        //         vehicleId: 3904,
        //         vehicleNo: "MH2020TR0881S",
        //         alertId: 53,
        //         alertMessage: "Ignition Off Alert"
        //     };
        //     this.onEventUpdateReceived(eventItem);
        // }, 5000);
    }

    componentWillUnmount() {
        window.authComponentUnloading = true;
        if (this.authWebSocketInterval != null) {
            clearTimeout(this.authWebSocketInterval);
        }
        if (this.client != null) {
            try {
                this.client.close();
            } catch (err) { console.log(err); }
        }
    }

    handleValidateSession = () => {
        try {
            if(this.sessionValidateInterval  != null){
                clearTimeout(this.sessionValidateInterval);
            }
            const requestParams = getRequestParams('GET');
            fetch(sessionStorage.getItem("REACT_APP_API_BASE_URL") + 'authentication/IsSessionAlive', requestParams)
                .then(response => { if (response.ok) return response.json() })
                .then(data => {
                    if (data != null) {
                        if (data.isSuccess) {
                            this.sessionValidateInterval = setTimeout(() => { this.handleValidateSession(); }, this.sessionTimeoutSec);
                        }
                        else {
                            this.handleInvalidSession();
                        }
                    }else{
                        this.handleInvalidSession();
                    }
                });
        } catch (ex) {
            console.log("Error in " + this.displayName + ".handleValidateSession function", ex);
        }
    }

handleInvalidSession = () => {
    secureLocalStorage.clear();
    Swal.fire({
        html: 'Your session expired. Please login again and retry.',
        icon: 'warning',
        showCancelButton: false,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Login',
        allowOutsideClick: false
    }).then((result) => {
        if (result.isConfirmed) {
            this.handleLogout();
        }
    });
}

    handleLogout = async () => {
        const requestParams = getRequestParams('GET');
        let userId = parseInt(secureLocalStorage.getItem("session_userId"));
        let loginHistoryId = parseInt(secureLocalStorage.getItem("session_LoginHistoryId"));        
        const response = await fetch(sessionStorage.getItem("REACT_APP_API_BASE_URL") + 'authentication/logout/' + userId + '/' + loginHistoryId, requestParams);
        //secureLocalStorage.clear();
        window.location.href = "/";
    }

    toggleNavbar = async () => {
        this.setState({
            collapsed: !this.state.collapsed
        });
    }
    handleMouseMove = event => {
        if (this.state.isOpenAutoLogoutModal === true) {
            this.setState({ isOpenAutoLogoutModal: false })
            this.handleReset();
        }
    };
    handleKeyPress = event => {
        if (this.state.isOpenAutoLogoutModal === true) {
            this.setState({ isOpenAutoLogoutModal: false })
            this.handleReset();
        }
    }
    handleOnActive = async () => {
        this.setState({ lastEvent: 'active' })
    }
    handleOnIdle = async (e) => {
        this.setState({ lastEvent: 'idle' })
        secureLocalStorage.clear();
        window.location.href = "/";
    }
    handleReset = async () => {
        this.idleTimer.reset()
    }
    handlePause = async () => {
        this.idleTimer.pause()
    }
    handleResume = async () => {
        this.idleTimer.resume()
    }
    setupWebSocket() {
        try {
            var client = new W3CWebSocket(this.state.eventUpatesWsurl);
            this.client = client;
            client.onopen = () => {
                console.log('WebSocket Client Connected');

                function establishValidConnection() {
                    if (client.readyState === client.OPEN) {
                        let sessRequest = JSON.stringify({
                            operation: 1,
                            user: {
                                username: secureLocalStorage.getItem("session_sessionId"),
                                password: secureLocalStorage.getItem("session_userName"),
                                userId: secureLocalStorage.getItem("session_userId")
                            }
                        });
                        client.send(sessRequest);
                    } else {
                        setTimeout(establishValidConnection, 1000);
                    }
                }
                establishValidConnection();
            };
            client.onmessage = (message) => {
                var eventData = JSON.parse(message.data);
                if (eventData.operation == 101) {
                    var eventUpdate = JSON.parse(eventData.message.body);
                    this.onEventUpdateReceived(eventUpdate);
                }
            };
            client.onerror = () => {
                console.log('WebSocket Connection Error');
            };
            client.onclose = () => {
                if (!window.authComponentUnloading) {
                    console.log('echo-protocol Client Closed ... reconnecting');
                    this.authWebSocketInterval = setTimeout(() => { this.setupWebSocket(); }, 5000);
                }
            };
        } catch (err) {
            console.log("Error in " + this.displayName + ".setupWebSocket function", err);
        }
    }

    onEventUpdateReceived = (eventUpdate) => {
        try {
            this.state.deviceObj = {};
            if (eventUpdate.vehicleId != 0) {
                document.dispatchEvent(new CustomEvent("ws_vehicle_eventupdate", {
                    detail: eventUpdate
                }));
            }
            if (eventUpdate.alertId != 0 && eventUpdate.vehicleId != 0) {
                if (this.state.vehicleList.filter((v) => v.vehicleId == eventUpdate.vehicleId).length == 0) return;
                let modal = this.state.alertsSettings.filter(item => item.alertId == eventUpdate.alertId)[0];
                if (modal != null) {
                    if (modal.enableNotificationPopup) {
                        //show notification
                        this.setState({ deviceObj: eventUpdate }, () => {
                            let data = this.state.deviceObj;
                            let modal = this.state.alertsSettings.filter(item => item.alertId == data.alertId)[0];
                            this.showVehicleAlertNotification(modal.enableClosure);
                        });
                    }
                }
            }
            //console.log("socket eventUpdate: " + JSON.stringify(eventUpdate));
        } catch (err) {
            console.log("Error in " + this.displayName + ".onEventUpdateReceived function", err);
        }
    }

    showVehicleAlertNotification = (isEnableClosure) => {
        try {
            let dataFromWeb = this.state.deviceObj;
            let vehicleAlertToastHtml = "";
            vehicleAlertToastHtml =
                <div onMouseEnter={this.handleMouseEnter} >
                    <div><h6>{dataFromWeb.vehicleNo}</h6></div>
                    <div><p style={{ marginBottom: '2px' }} >{dataFromWeb.eventTime}</p></div>
                    <div><p style={{ marginBottom: '2px' }} >{dataFromWeb.alertMessage}</p></div>
                    <div className='row'>
                        <NavLink tag={Link} to="/vehiclealert" title="Alerts & Notifications" style={{ textDecoration: 'underline', color: 'white' }} >View All</NavLink>&nbsp;
                        <span style={{ display: (isEnableClosure ? 'block' : 'none') }}><NavLink tag={Link} onClick={() => { this.viewSnoozedOrClosedAlertModel(dataFromWeb.vehicleId, dataFromWeb.alertId, dataFromWeb.eventTime) }} to="#" style={{ textDecoration: 'underline', color: 'white' }} >Closure</NavLink></span>
                    </div>
                </div>
                ;
            if (this.toastId.current != null) {
                toast.dismiss(this.toastId.current);
            }
            this.toastId.current = toast.info(vehicleAlertToastHtml);
            // this.toastId.current = toast.info(vehicleAlertToastHtml, {
            //     position: toast.POSITION.BOTTOM_RIGHT,
            // });
        } catch (err) {
            console.log("Error in " + this.displayName + ".showVehicleAlertNotification function", err);
        }
    }

    viewSnoozedOrClosedAlertModel = async (vehicleId, alertId, alertTime) => {
        try {
            const requestParams = getRequestParams('GET');

            let date = (alertTime.toString()).replace(/[\W-:+T]/g, '');
            // const response = await fetch(sessionStorage.getItem("REACT_APP_API_BASE_URL") + 'VehicleAlerts/GetID/' + vehicleId + '/' + alertId + "/" + date, requestParams);
            // const id = await response.json();
            // if (id != 0) {
            //     const result = await fetch(sessionStorage.getItem("REACT_APP_API_BASE_URL") + 'VehicleAlerts/' + id + '/' + date, requestParams);
            //     const data = await result.json();
            //     if (data.isSuccess) {
            //         this.refs.snoozedOrClosedVehicleAlertsComponent.getVehicleAlertById(id, date);
            //     }
            // }

            fetch(sessionStorage.getItem("REACT_APP_API_BASE_URL") + 'VehicleAlerts/GetID/' + vehicleId + '/' + alertId + "/" + date, requestParams)
                .then(response => { if (response.ok) return response.json() })
                .then(id => {
                    if (id != 0) {
                        fetch(sessionStorage.getItem("REACT_APP_API_BASE_URL") + 'VehicleAlerts/' + id + '/' + date, requestParams)
                            .then(response => { if (response.ok) return response.json() })
                            .then(data => {
                                if (data != null) {
                                    if (data.isSuccess) {
                                        this.refs.snoozedOrClosedVehicleAlertsComponent.getVehicleAlertById(id, date);
                                    }
                                }
                            });
                    }
                });
        } catch (err) {
            console.log("Error in " + this.displayName + ".viewSnoozedOrClosedAlertModel function", err);
        }
    }

    getAccountAlertSettings = () => {
        try {
            const requestParams = getRequestParams('GET');
            let userId = secureLocalStorage.getItem('session_userId');
            let accountId = secureLocalStorage.getItem('session_accountId');
            let locationId = secureLocalStorage.getItem('session_locationId');
            fetch(sessionStorage.getItem("REACT_APP_API_BASE_URL") + 'VehicleAlerts/Settings/' + userId + '/' + accountId + "/" + locationId, requestParams)
                .then(response => { if (response.ok) return response.json() })
                .then(data => {
                    if (data != null) {
                        if (data.isSuccess) {
                            this.setState({
                                alertsSettings: data.payload != null ? data.payload : [],
                            });
                        }
                        else {
                            this.setState({ alertsSettings: [] })
                        }
                    }
                });
        } catch (err) {
            console.log("Error in " + this.displayName + ".getAccountAlertSettings function", err);
        }
    }

    getVehicleDropdown = () => {
        try {
            const requestParams = getRequestParams('GET');
            let userId = secureLocalStorage.getItem('session_userId');
            let accountId = secureLocalStorage.getItem('session_accountId');
            let locationId = secureLocalStorage.getItem('session_locationId');
            fetch(sessionStorage.getItem("REACT_APP_API_BASE_URL") + 'Vehicles/DropdownList/' + userId + "/" + accountId + "/" + locationId, requestParams)
                .then(response => { if (response.ok) return response.json() })
                .then(data => {
                    if (data != null) {
                        if (data.isSuccess) {
                            this.setState({
                                vehicleList: data.payload,
                            });
                        }
                        else {
                            this.setState({ vehicleList: [] })
                        }
                    }
                });
        } catch (ex) {
            console.log("Error in " + this.displayName + ".getVehicleDropdown function", ex);
        }
    }

    handleMouseEnter = async () => { //For MarkAsRead
        try {
            let dataFromWeb = this.state.deviceObj;
            var request = new request();
            request.Id = 0;
            request.UserId = secureLocalStorage.getItem('session_userId')
            request.AlertId = dataFromWeb.alertId;
            request.AlertTime = dataFromWeb.eventTime;
            request.VehicleId = dataFromWeb.vehicleId;
            const requestParams = getRequestParams('POST', request);
            // const response = await fetch(sessionStorage.getItem("REACT_APP_API_BASE_URL") + 'VehicleAlerts/Read/', requestParams);
            // const data = await response.json();
            // if (data.isSuccess) {
            //     // this.refs.snoozedOrClosedVehicleAlertsComponent.getVehicleAlertById(id, date);
            // }
            fetch(sessionStorage.getItem("REACT_APP_API_BASE_URL") + 'VehicleAlerts/Read/', requestParams)
                .then(response => { if (response.ok) return response.json() })
                .then(data => {
                    if (data != null) {
                        if (data.isSuccess) { }
                    }
                });
        } catch (err) {
            console.log("Error in " + this.displayName + ".handleMouseEnter function", err);
        }
    }

    render() {
        return (
            <Fragment>
                <IdleTimer
                    ref={ref => { this.idleTimer = ref }}
                    onActive={this.handleOnActive}
                    onIdle={this.handleOnIdle}
                    timeout={this.timeout}
                    crossTab={{
                        emitOnAllTabs: true
                    }}
                />
                <div className="main-wrapper">
                    <SideBarComponent />
                    <TopBarComponent />
                    <div name="mainContent" className="main-content" style={{ height: "80vh" }}>
                        <section className="section">
                            <div className="section-body" style={{ backgroundColor: "#ffffff", height: "92vh" }}>
                                {this.props.children}
                            </div>
                        </section>
                    </div>
                </div>
                <SnoozedOrClosedVehicleAlertsComponent ref="snoozedOrClosedVehicleAlertsComponent" reloadData={this.getAccountAlertSettings} />
                <ToastContainer
                    id={this.toastId.current}
                    position="bottom-right"
                    autoClose={5000}
                    hideProgressBar={false}
                    newestOnTop={false}
                    style={{ width: "250px" }}
                    closeOnClick
                    rtl={false}
                    pauseOnFocusLoss
                    draggable
                    pauseOnHover
                />
            </Fragment>
        );
    }
}