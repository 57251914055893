import React, { Component, Fragment } from 'react';
import { NavItem, NavLink } from 'reactstrap';
import { Link } from 'react-router-dom';
import { FeatureActionType } from '../helpers/fixcodes.js'
import { getRequestParams, getVehicleLabelByIndustry } from '../../src/helpers/globalfunctions.js';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import  secureLocalStorage  from  "react-secure-storage";

var nav = document.getElementById("nav-item dropdown");

export class SideBarComponent extends Component {
    _isMounted = false;
    static displayName = SideBarComponent.name;
    nav = document.getElementById('access_nav');
    constructor(props) {
        super(props);
        this.state = {
            pageName: "Dashboard",
            menuHtml: null,
            userMenu: [],
            accountProfileImage: "../assets/img/defaultlogo.png",
            fileGuid: '00000000-0000-0000-0000-000000000000',
            fileUrl:"../assets/img/defaultlogo.png",
        }
    }
    componentDidMount() {
        //document.querySelector("#lblPageTitle").innerHTML = sessionStorage.getItem("session_PageName") === null ? "Dashboard" : sessionStorage.getItem("session_PageName");
        setTimeout(() => {
            document.querySelector("#lblPageTitle").innerHTML = sessionStorage.getItem("session_PageName") === null ? "Dashboard" : sessionStorage.getItem("session_PageName");
         }, 1000);
        this._isMounted = true;
        this.getUserRoleFeatureList();
        this.getAccountLogo();
    }
    componentWillUnmount() {
        this._isMounted = false;
    }
    handleLogout = async (e) => {
        e.preventDefault();        
        const requestParams = getRequestParams('GET');
        let userId = parseInt(secureLocalStorage.getItem("session_userId"));
        let loginHistoryId = parseInt(secureLocalStorage.getItem("session_LoginHistoryId"));        
        const response = await fetch(sessionStorage.getItem("REACT_APP_API_BASE_URL") + 'authentication/logout/' + userId + '/' + loginHistoryId, requestParams);
        secureLocalStorage.clear();
        window.location.href = "/";
    }
    getAccountLogo = async () => {
        try {
            var fileGuid = secureLocalStorage.getItem("session_fileGuid");
            var accountLogo = secureLocalStorage.getItem("session_accountLogoName");
            var logoExtenstion = secureLocalStorage.getItem("session_accountLogoExt");
            // if (fileGuid !== "00000000-0000-0000-0000-000000000000") {
            //     const requestParams = getRequestParams('GET');                
            //     const response = await fetch(sessionStorage.getItem("REACT_APP_API_BASE_URL") + 'File/download/' + fileGuid, requestParams);
            //     const data = await response.json();                
            //     this.setState({ 
            //         accountProfileImage: 'data:' + data.payload.mimeType + ';base64,' + data.payload.fileContent,
            //         fileUrl: data.payload.fileUrl });
            // }

            if (accountLogo != "") {
                this.setState({
                    fileUrl: "../assets/img/accountLogo/" + accountLogo + logoExtenstion
                });
            }
        }
        catch (err) {
            console.log("Error in " + this.displayName + ".getAccountLogo function", err);
            this.setState({ loadPanelVisible: false });
        }
    }
    getUserRoleFeatureList = async () => {
        try {

            let userId = secureLocalStorage.getItem('session_userId');
            let planId = secureLocalStorage.getItem('session_planId');
            const requestParams = getRequestParams('GET');
            this.setState({
                loadPanelVisible: true
            });

            var sessionmenu = JSON.parse(secureLocalStorage.getItem("session_menu"));
            if (sessionmenu === null) {
                const response = await fetch(sessionStorage.getItem("REACT_APP_API_BASE_URL") + 'UserPermission/' + userId + '/role-feature/' + parseInt(planId) + '/' + Number(FeatureActionType.UTWiz), requestParams);
                if (response.ok) {
                    let data = await response.json();
                    secureLocalStorage.setItem("session_menu", JSON.stringify(data));
                    data = JSON.parse(JSON.stringify(data.userRoleFeature));
                    data = data.filter(el => el.isActive === true);
                    let menuHtmlItem = this.getMenuHtml(data);
                    this.setState({
                        menuHtml: menuHtmlItem,
                        userMenu: data,
                    });
                }
            }
            else {
                let data = sessionmenu.userRoleFeature;
                data = data.filter(el => el.isActive === true);
                let menuHtmlItem = this.getMenuHtml(data);
                this.setState({
                    menuHtml: menuHtmlItem,
                    userMenu: data,
                });
            }
        } catch (err) {
            console.log("Error in " + this.displayName + ".getUserRoleFeatureList function", err);
            this.setState({ loadPanelVisible: false });
        }
    }
    getMenuHtml(menuFeature) {
        let menuHtmlItem = [];
        menuFeature = JSON.parse(JSON.stringify(menuFeature));
        menuFeature.forEach((menu) => {
            if (menu.parentMenuId === 0) {
                let childMenu = menuFeature.filter(el => el.parentMenuId === menu.childMenuId);
                const menuName = getVehicleLabelByIndustry(menu.menuName);
                if (childMenu.length > 0) {
                    menuHtmlItem.push(
                        <li key={menu.childMenuId} className="dropdown">
                            <a href={menu.componentPath === "" ? "/" : menu.componentPath} className="nav-link has-dropdown" title={menuName} data-toggle="dropdown">
                                <i className={menu.className}></i><span>{menuName}</span>
                            </a>
                            <ul key={menu.childMenuId} className="dropdown-menu">
                                {
                                    this.subMenuHtml(menuFeature, menu)
                                }
                            </ul>
                        </li>
                    );
                }
                else {
                    menuHtmlItem.push(
                        <NavItem key={menu.childMenuId}>
                            <NavLink tag={Link} to={menu.componentPath} title={menuName} onClick={(e) => { this.menuClick(e, menuName) }}>
                                <i className={menu.className}></i><span>{menuName}</span>
                            </NavLink>
                        </NavItem>
                    );
                }
            }
        });
        return menuHtmlItem;
    }

    subMenuHtml = (menuFeature, menuList) =>
        menuFeature.filter(el => el.parentMenuId === menuList.childMenuId)
            .map(subchild =>
                (menuFeature.filter(el => el.parentMenuId === subchild.childMenuId).length === 0) ?
                    <NavItem key={subchild.childMenuId}>
                        <NavLink tag={Link} to={subchild.componentPath} title={subchild.menuName} onClick={(e) => { this.menuClick(e, subchild.menuName) }}>
                            <i className={subchild.className}></i><span>{subchild.menuName}</span>
                        </NavLink>
                    </NavItem>
                    : null
            )

    menuClick = async (e, currentPageName) => {
        document.querySelector("#lblPageTitle").innerHTML = currentPageName;
        if (currentPageName === "Fuel (Legacy Site)") {
            let accountId = parseInt(secureLocalStorage.getItem('session_accountId'));
            let locationId = parseInt(secureLocalStorage.getItem('session_locationId'));
            let userId = parseInt(secureLocalStorage.getItem('session_userId'));

            const requestParams = getRequestParams('GET');
            console.log(sessionStorage.getItem("REACT_APP_API_BASE_URL"));
            const response = await fetch(sessionStorage.getItem("REACT_APP_API_BASE_URL") + 'authentication/wialon-authentication/' + accountId + "/" + locationId + "/" + userId, requestParams);
            const data = await response.json();

            if (data.payload != null && data.payload != undefined) {
                window.open(data.payload, "_blank")
            }
            else {
                toast.success("Invalid access", {
                    position: toast.POSITION.BOTTOM_CENTER,
                    autoClose: 5000,
                    style: { width: "310px" },
                    hideProgressBar: false,
                    newestOnTop: false,
                });
            }
        }
        else {
            this.setState({
                menuName: e.currentTarget.innerText,
                pageName: currentPageName,
            });
            sessionStorage.setItem("session_PageName", currentPageName);
        }
    };

    render() {
        return (
            <Fragment>
                <div className="main-sidebar">
                    <aside id="sidebar-wrapper">
                        {/* <div className="sidebar-brand">
                            <a href="/">{secureLocalStorage.getItem("session_accountname")}</a>
                        </div> */}
                        <div className="sidebar-brand">
                            <a href="/">
                                <img src="https://www.sstelematics.com/Content/images/Logo.png" className="silverstarLogo" />
                            </a>
                            <div className="utwizLogo">a product of
                                <img src="https://www.utwiz.com/wp-content/uploads/2019/05/UTWiz-Logo-small.png" />
                            </div>
                        </div>
                        <div className="sidebar-brand-silverstar-sm">
                            <a href="/">UT</a>
                        </div>
                        <div className="companyLogoBox sidebar-brand-sm">
                            {/* <img src="https://upload.wikimedia.org/wikipedia/commons/f/fa/Amul_dairy_logo.png" /> */}
                            {/* <img src="../assets/img/defaultlogo.png" /> */}
                            <img src={this.state.fileUrl} alt="Account Logo" />
                        </div>
                        <div className="sidebar-brand sidebar-brand-sm">
                            <a href="/">{secureLocalStorage.getItem("session_accountname").charAt(0).toLowerCase().trim()}</a>
                        </div>
                        <ul className='sidebar-menu'>
                            {this.state.menuHtml}                                
                        </ul>
                    </aside>                    
                </div>
                <div className='plexitechPoweredByLogo'>powered by
                    <img src="../assets/img/logo-plexitech2.png" />
                </div>
                {/* <TopBarComponent pageName={sessionStorage.getItem("session_PageName")} /> */}
                {/* <TopBarComponent pageName={this.pageName} /> */}
            </Fragment>
        );
    }
}